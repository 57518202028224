
import { StorageService } from "src/app/core/common/storage.service";
var currenttenant = StorageService.getTenant();
export const environment = {
    uat: false,
    production: true,
    //apiUrl: (currenttenant && currenttenant != null && currenttenant != "0")? `https://buvhdje2qm.us-east-2.awsapprunner.com/api/${currenttenant}/` : 'https://buvhdje2qm.us-east-2.awsapprunner.com/api/',
    // apiUrl: (currenttenant && currenttenant != null && currenttenant != "0") ? `https://3wenp2u9c2.us-east-2.awsapprunner.com/api/api/${currenttenant}/` : 'https://3wenp2u9c2.us-east-2.awsapprunner.com/api/api/',
    apiUrl: (currenttenant && currenttenant != null && currenttenant != "0") ? `https://app.tatracker.cloud/api/api/${currenttenant}/` : 'https://app.tatracker.cloud/api/api/',
    //apiUrl2: 'https://buvhdje2qm.us-east-2.awsapprunner.com/api/',
    // apiUrl2: 'https://3wenp2u9c2.us-east-2.awsapprunner.com/api/api/'
    apiUrl2: 'https://app.tatracker.cloud/api/api/'
};