import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { AppSubHeaderComponent } from 'src/app/shared/components';
import { SharedModule } from 'src/app/shared/shared.module';
import { SuperAdminComponent } from './pages/super-admin/super-admin.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [SuperAdminComponent],
  imports: [
    CommonModule, SuperAdminRoutingModule, FontAwesomeModule, ReactiveFormsModule
  ]
})
export class SuperAdminModule { }
