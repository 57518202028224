import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import {
  faCaretUp,
  faCaretDown,
  faCompress,
  faShare,
  faL,
} from '@fortawesome/free-solid-svg-icons';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { PagerService } from 'src/app/core/common/pager.service';
import { NotesService } from 'src/app/core/services/notes/notes.service';
import { CandidateComponent } from 'src/app/modules/candidate/pages/candidate/candidate.component';
import { AppSubHeaderComponent } from 'src/app/shared/components';
//import { AppSearchEditComponent } from 'src/app/shared/components';
import { ClientService } from 'src/app/core/services/clients/client.service';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { UserService } from 'src/app/core/services/users/user.service';
import { LoaderService } from 'src/app/core/services/auth/loader.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

export function endDateValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const startDate = control.get('official_start_date');
  const endDate = control.get('official_end_date');

  if (
    startDate &&
    endDate &&
    startDate.value &&
    endDate.value &&
    startDate.value > endDate.value
  ) {
    return { endDateInvalid: true };
  }
  return { endDateInvalid: false };
}

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {
  UpdateNoteForm!: FormGroup;
  CreateNoteForm!: FormGroup;
  CreateCandidateForm!: FormGroup;
  UpdateProjectCandidateForm!: FormGroup;
  AddUserToSearchForm!: FormGroup;
  FilterForm!: FormGroup;
  CreateCandidateManuallyForm!: FormGroup;
  projectDetail: any;
  closeResult: string = '';
  notesList: any = [];
  genderList: any = [];
  pageNumber: number = 1;
  searchPageNumer: number = 1;
  pageSize: number = 10;
  totalRows: number = 0;
  pager: any = {};
  faCompress = faCompress;
  faShare = faShare;
  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  lastSortedField: string = '';
  lastSortedDirection: string = '';
  sortfield: any;
  sortmethod: any;
  CandidateName: any = '';
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  candidateList: any = [];
  candidateData: any;
  CandidateId: any;
  deleteNoteId: any;
  deleteCandidateName: any;
  downloadUrl: any;
  submitted: boolean = false;
  pageTitle: string = 'Candidates';
  isSearchable: boolean = true;
  showCreateButton: string = 'candidate';
  showFilterOptions: boolean = false;
  editNoteSearchName: any = '';
  updatesubmitted: boolean = false;
  imageselected: boolean = false;
  imagepath: string = '';
  strengthId: any;
  deleteCandidateId: any;
  allowExportcheck: any;
  projectRank: any;
  searchCandidateRank: any;
  projectStatus: any;
  projectId: any;
  projectData: any = [];
  projectUpdate: any;
  selectedCandidateStatus: any;
  isdisable: boolean = false;
  filterStrengthId: string = '';
  createNoteError: any;
  eitherSelectError: boolean = false;
  createCandidateData: any;
  createNoteErrorNotes: any;
  createNoteErrorAllowexport: any;
  updateNoteError: any;
  updateNoteErrorNotes: any;
  updateNoteErrorAllowexport: any;
  projectapicall: boolean = false;
  projectapicall3: boolean = false;
  createCandidateError: any;
  createCandidateErrorUrl: any;
  createCandidateErrorProjectId: any;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  projectList: any = '';
  createCandidateManuallyError: any;
  modalRef!: NgbModalRef;
  top: number = 90;
  left: number = 80;
  initialX!: number;
  initialY!: number;
  dragging: boolean = false;
  candidateTypeSelected: number = 1;
  candidate_exists: any;
  linkedin_candidate: boolean = true;
  createCandidateManuallyData: any;
  createCandidateManuallyConfirmation: boolean = false;
  manually_submitted: any = -1;
  searchInfoid!: any;
  publishOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];
  resumePaths!: { [key: string]: string };
  
  @ViewChild('ispublic') ispublic: any;
  // projectList: any = [];
  clientList = [];
  projectTypeList = [];
  projectStatusList = [];
  stateList = [];
  statusofprojectList = [];
  createProjectError: string = '';
  UpdateProjectForm!: FormGroup;
  selectedclientValue: any;
  clientSearchValue: string = '';
  createProjectErrorName: any;
  createProjectErrorClientname: any;
  createProjectErrorType: any;
  createProjectErrorProjectstatusid: any;
  createProjectErrorOfficialstartdate: any;
  createProjectErrorOfficialenddate: any;
  createProjectErrorStreetaddress: any;
  createProjectErrorPostalcode: any;
  createProjectErrorCity: any;
  createProjectErrorState: any;
  createProjectErrorDescription: any;
  clientsList = [];
  searchvalue: string = '';
  currentAppliedFilter: any;
  searchListSettings: any;
  getSearchList: any;
  candidateStatus: any;
  fileName!: string;
  filePath!: string;
  files: any;
  openDocumentSection: boolean = false;
  openDocumentButtton: boolean = true;
  documentData: any;
  ProjectdocId!: number;
  deleteDocumentId: any;
  uploadsubmitted: boolean = false;
  CreatDocumentsErrorDocuments: any;
  CreatDocumentsErrorDocumentsname: any;
  showexternallink1: boolean = false;
  showexternallink2: boolean = false;
  showexternallink3: boolean = false;
  editexternallink1: boolean = false;
  editexternallink2: boolean = false;
  editexternallink3: boolean = false;
  weblink1: string = '';
  weblink2: string = '';
  weblink3: string = '';
  createProjectErrorWeblinkone: any;
  createProjectErrorWeblinktwo: any;
  createProjectErrorWeblinkthree: any;
  userinitials: any;
  imgSrc: any;
  tenantlogin: any = '1';
  userprofile: any = [];
  userList: any = [];
  searchUserList: any = [];
  // searchvalue: string = '';
  isSearchClicked: boolean = false;
  isClearClicked: boolean = false;
  resetButtonClicked: boolean = false;

  private hasScrolledToBottom = false;

  diverseList: any = [
    { name: 'Yes', value: '1' },
    { name: 'No', value: '0' },
  ];
  mouseOnColumnName: string = '';
  isEmailNotes: boolean = false;
  getCandidateSearchList: any;

  get f() {
    return this.UpdateProjectForm.controls;
  }
  get createCandidateForm() {
    return this.CreateCandidateForm.controls;
  }
  get createNoteForm() {
    return this.CreateNoteForm.controls;
  }
  get updateNoteForm() {
    return this.UpdateNoteForm.controls;
  }
  get createCandidateManuallyForm() {
    return this.CreateCandidateManuallyForm.controls;
  }
  get addUserToSearchForm() {
    return this.AddUserToSearchForm.controls;
  }

  get jobupdate() {
    return this.updateJobPostingForm.controls;
  }

  @ViewChild('formDirective')
  projectapicall2: boolean = false;

  updateJobPostingForm: FormGroup;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'insertImage',
        'subscript',
        'superscript',
        'clearFormatting',
        'heading',
        'insertVideo',
        'customClasses',
        'toggleEditorMode',
      ],
    ],
  };

  private formDirective!: NgForm;
  constructor(
    private projectService: ProjectService,
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private pagerService: PagerService,
    private router: Router,
    private notesService: NotesService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private userservice: UserService,
    private loadingService: LoaderService
  ) {
    this.updateJobPostingForm = this.formBuilder.group({
      projectId: [null],
      name: [null, [Validators.required]],
      description: [null],
      project_status_id: [null],
      city: [null],
      state: [null],
      is_public: [null],
      clientname: [null],
    });
  }

  //  @HostListener('window:scroll', ['$event'])
  //     onWindowScroll(event: any) {
  //       if (this.candidateList.length < this.pager.totalItems) {
  //         const scrollPosition = window.scrollY || window.pageYOffset;
  //         const windowHeight = window.innerHeight;
  //         const documentHeight = document.documentElement.scrollHeight;
    
  //         if (scrollPosition + windowHeight >= documentHeight - 10) {
  //           if (!this.hasScrolledToBottom) {
  //             this.hasScrolledToBottom = true;
  //             this.setPage(this.pager.currentPage + 1);
  //           }
  //         } else {
  //           this.hasScrolledToBottom = false;
  //         }
  //       }
  //     }

  ngOnInit(): void {
    this.onBuildForm();
    this.onLoad();
    this.getCandidateStatus();
    this.getUserProfile();
    this.getUserList();
    this.pageTitle = localStorage.getItem('page_name') || 'Searches';
    
      //  let listSettings: any = this.storageService.getValue(
      //       StorageKey.listSettings
      //     );
      //     if (listSettings !== 'undefined' && listSettings)
      //       this.getSearchList = JSON.parse(listSettings);
      //     if (this.getSearchList && this.getSearchList.searches) {
      //       let existing_filter = this.getSearchList.searches.apply_filter;
      //       if (existing_filter) {
      //         this.showFilterOptions = true;
      //         let apply_filter = existing_filter.split('&');
      //         apply_filter.forEach((e: any) => {
      //           let f_option = e.split('=');
      //           if (f_option[0] == 'project_status_id')
      //             this.FilterForm.controls['f_position'].setValue(f_option[1]);
      
      //           if (f_option[0] == 'project_type_id')
      //             this.FilterForm.controls['f_company'].setValue(f_option[1]);
      
      //           if (f_option[0] == 'client_id')
      //             this.FilterForm.controls['f_past_company'].setValue(f_option[1]);
      
      //           if (f_option[0] == 'search_city')
      //             this.FilterForm.controls['f_compensation'].setValue(f_option[1]);
      
      //           if (f_option[0] == 'search_state')
      //             this.FilterForm.controls['f_city'].setValue(f_option[1]);
      //         });
      
      //         this.getCandidateList(null, this.getSearchList.candidates, false);
          //   }
          // }
      
          // this.pageTitle = localStorage.getItem('page_name') || 'Candidates';
          // this.is_public = this.pageTitle === 'Job Posting' ? 1 : 0;
          // this.setCandidateList(); // Call the function to set project list initially
      
          // this.routeSubscription = this.router.events
          //   .pipe(filter((event) => event instanceof NavigationEnd))
          //   .subscribe(() => {
          //     let typeQueryParam =
          //       this.router.routerState.snapshot.root.queryParams['type'] || '';
          //     if (
          //       (typeQueryParam === 'search' && this.pageTitle === 'Job Posting') ||
          //       (typeQueryParam === 'jobposting' && this.pageTitle === 'Searches')
          //     ) {
          //       window.location.reload();
          //     }
          //     if (typeQueryParam === 'candidate-list') {
          //       this.pageTitle = 'Candidate';
          //       // this.is_public = 0;
          //     } /* else if (typeQueryParam === 'jobposting') {
          //       this.pageTitle = 'Job Posting';
          //       this.is_public = 1;
          //     } */
          //     this.setCandidateList(); // Call the function to set project list after updating variables
          //   });
    
  }

  // onLoad() {

  //   if (this.route.snapshot.params['projectrank']) {
  //     this.searchCandidateRank = this.route.snapshot.params['projectrank']
  //     this.pageNumber = 1;
  //   }
  //   else {
  //     this.searchCandidateRank = "";
  //   }

  //   if (this.route.snapshot.params['searchvalue']) {
  //     this.searchvalue = this.route.snapshot.params['searchvalue']
  //   }
  //   else {
  //     this.searchvalue = "";
  //     // this.searchCandidateRank = "";
  //   }

  //   // this.getCandidateList();
  //   this.getCandidateFullList();

  //   this.projectService.projectDetail(this.route.snapshot.params['id']).subscribe({
  //     next: (response: any) => {
  //       this.projectDetail = response.project;
  //       this.projectDetail["fullName"] = this.projectDetail?.name + (this.projectDetail.client?.name ? " [" + this.projectDetail.client?.name + "]" : "")
  //       //  this.projectDetail.forEach((ele:any)=>{ele["dropdown"]=ele?.name + (ele.client?.name?" ["+ele.client?.name+"]":"")})

  //       this.projectId = this.projectDetail.id;
  //       this.searchUserList = this.projectDetail.users;
  //     },
  //     error: (error) => {
  //       this.notificationService.showError(error.message);
  //     }
  //   });

  //   this.searchInfoid = this.route.snapshot.params['id'];

  //   let listSettings: any = this.storageService.getValue(StorageKey.listSettings);

  //   if (listSettings !== "undefined" && listSettings)
  //     this.getCandidateSearchList = JSON.parse(listSettings);

  //   if (this.getCandidateSearchList && this.getCandidateSearchList.candidates_search) {
  //     if (this.searchvalue == "") {
  //       this.searchvalue = this.getCandidateSearchList.candidates_search.search
  //     }

  //     let existing_filter = this.getCandidateSearchList.candidates_search.apply_filter;

  //     if (existing_filter) {
  //       this.showFilterOptions = true;
  //       let apply_filter = existing_filter.split('&');
  //       apply_filter.forEach((e: any) => {
  //         let f_option = e.split('=');
  //         if (f_option[0] == 'candidate_status_id')
  //           this.FilterForm.controls['f_candidate_status_id'].setValue(f_option[1]);

  //         if (f_option[0] == 'strength')
  //           this.showfilterimage('./assets/img/' + f_option[1] + '.png', f_option[1]);

  //         if (f_option[0] == 'search_gender')
  //           this.FilterForm.controls['f_gender'].setValue(f_option[1]);

  //         if (f_option[0] == 'search_compensation')
  //           this.FilterForm.controls['f_compensation'].setValue(f_option[1]);

  //         if (f_option[0] == 'search_diversity')
  //           this.FilterForm.controls['f_diversity'].setValue(f_option[1]);

  //       });
  //     }

  //     if (this.route.snapshot.params['projectrank'] && this.searchvalue) {
  //       this.searchCandidateRank = "";
  //     }

  //     this.getCandidateList(null, this.getCandidateSearchList.candidates_search);
  //   } else

  //     this.getCandidateList({});
  // }

  // setCandidateList() {
  //   let data = {
  //     page: this.pageNumber,
  //     sort_field: this.currentSortedColumn,
  //     sort_method: this.currentSortedMethod,
  //     // multipleSorting: this.multipleSorting,
  //     column_name: this.sortedColumn,
  //     // search: this.currentSearchValue,
  //     search: this.searchvalue,
  //     apply_filter: this.currentAppliedFilter,
  //     user_id: null,
  //   };
  //   this.getCandidateList(null, data, false);
  // }

  onLoad() {
    if (this.route.snapshot.params['projectrank']) {
      this.searchCandidateRank = this.route.snapshot.params['projectrank'];
      this.pageNumber = 1;
    } else {
      this.searchCandidateRank = '';
    }

    if (this.route.snapshot.params['searchvalue']) {
      this.searchvalue = this.route.snapshot.params['searchvalue'];
    } else {
      this.searchvalue = '';
    }

    // Initialize sorting parameters with default values or from saved settings
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings) {
      this.getCandidateSearchList = JSON.parse(listSettings);
    }

    if (
      this.getCandidateSearchList &&
      this.getCandidateSearchList.candidates_search
    ) {
      const candidatesSearch = this.getCandidateSearchList.candidates_search;
      if (this.searchvalue == '') {
        this.searchvalue = candidatesSearch.search;
      }

      this.currentSortedColumn = candidatesSearch.sort_field || 'in_last_name';
      this.currentSortedMethod = candidatesSearch.sort_method || 'asc';

      let existing_filter = candidatesSearch.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'candidate_status_id') {
            this.FilterForm.controls['f_candidate_status_id'].setValue(
              f_option[1]
            );
          }
          if (f_option[0] == 'strength') {
            this.showfilterimage(
              './assets/img/' + f_option[1] + '.png',
              f_option[1]
            );
          }
          if (f_option[0] == 'search_gender') {
            this.FilterForm.controls['f_gender'].setValue(f_option[1]);
          }
          if (f_option[0] == 'search_compensation') {
            this.FilterForm.controls['f_compensation'].setValue(f_option[1]);
          }
          if (f_option[0] == 'search_diversity') {
            this.FilterForm.controls['f_diversity'].setValue(f_option[1]);
          }
        });
      }

      if (this.route.snapshot.params['projectrank'] && this.searchvalue) {
        this.searchCandidateRank = '';
      }

      this.getCandidateList(null, candidatesSearch);
    } else {
      this.currentSortedColumn = 'in_last_name'; // Default sort field
      this.currentSortedMethod = 'asc'; // Default sort method
      this.getCandidateList({});
    }

    this.getCandidateFullList();

    this.projectService
      .projectDetail(this.route.snapshot.params['id'])
      .subscribe({
        next: (response: any) => {
          this.projectDetail = response.project;
          this.projectDetail['fullName'] =
            this.projectDetail?.name +
            (this.projectDetail.client?.name
              ? ' [' + this.projectDetail.client?.name + ']'
              : '');
          this.projectId = this.projectDetail.id;
          this.searchUserList = this.projectDetail.users;
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });

    this.searchInfoid = this.route.snapshot.params['id'];
  }

  getCandidateStatus() {
    this.candidateService.getCandidateStatus().subscribe({
      next: (response: any) => {
        this.candidateStatus = response.candidate_statuses.data.filter(
          (e: any) => {
            return e.is_active == 1;
          }
        );
      },
      error: (error) => {},
    });
  }

  showFilter() {
    // this.getStateList();
    this.showFilterOptions = !this.showFilterOptions;
  }

  onRefreshClick() {
    this.pageNumber = 1;
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getCandidateSearchList = JSON.parse(listSettings);
    if (
      this.getCandidateSearchList &&
      this.getCandidateSearchList.candidates_search
    ) {
      delete this.getCandidateSearchList.candidates_search;
      this.storageService.setValue(
        'listSettings',
        JSON.stringify(this.getCandidateSearchList)
      );
    }

    this.iconVisible = true;
    this.currentSortedColumn = '';
    this.searchvalue = '';
    this.FilterForm.reset();
    this.currentAppliedFilter = null;
    this.showFilterOptions = false;
    this.imageselected = false;
    this.getCandidateList({}, undefined, true);
  }

  createProjectForm() {
    this.UpdateProjectForm = this.formBuilder.group(
      {
        projectId: [null],
        name: [null, [Validators.required]],
        clientname: [null],
        official_start_date: [null, [Validators.required]],
        official_end_date: [null],
        street_address: [null],
        postal_code: [null],
        city: [null],
        state: [null],
        type: [null],
        description: [null],
        project_status_id: [null],
        web_link_one: [null, [Validators.pattern(this.getURLPattern())]],
        web_link_two: [null, [Validators.pattern(this.getURLPattern())]],
        web_link_three: [null, [Validators.pattern(this.getURLPattern())]],
        document: [null],
        user: [null],
        is_public: [null],
      },
      { validator: endDateValidator }
    );
  }

  onBuildForm() {
    // this.CreateCandidateForm = this.formBuilder.group({
    //   linkedInUrl: [null, [Validators.required]]
    // });
    this.CreateCandidateManuallyForm = this.formBuilder.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      email: [null, [Validators.pattern(this.emailPattern)]],
      // projectId: [null],
      projectId: [{ value: '', disabled: true }],
    });

    this.CreateCandidateForm = this.formBuilder.group({
      linkedInUrl: [null],
      projectId: [{ value: '', disabled: true }],
      candidateId: [null],
    });
    // this.CreateCandidateForm.get('linkedInUrl')?.valueChanges.subscribe(value => {
    //   const inputField2 = this.CreateCandidateForm.get('candidateId');
    //   if (inputField2) {
    //     if (value) {
    //       inputField2.disable();
    //     } else {
    //       inputField2.enable();
    //     }
    //   }
    // });

    // this.CreateCandidateForm.get('candidateId')?.valueChanges.subscribe(value => {
    //   const inputField1 = this.CreateCandidateForm.get('linkedInUrl');
    //   if (inputField1) {
    //     if (value) {
    //       inputField1.disable();
    //     } else {
    //       inputField1.enable();
    //     }
    //   }
    // });
    // this.CreateCandidateForm.statusChanges.subscribe(status => {
    //   const input1Control = this.CreateCandidateForm.get('input1');
    //   const input2Control = this.CreateCandidateForm.get('input2');

    //   if (input1Control && input2Control) {
    //     if (status === 'VALID') {
    //       input1Control.disable();
    //       input2Control.disable();
    //     } else {
    //       input1Control.enable();
    //       input2Control.enable();
    //     }
    //   }
    // });

    this.CreateNoteForm = this.formBuilder.group({
      notes_project: [null],
      notes: [null, [Validators.required]],
      allow_export: [null],
    });

    this.UpdateNoteForm = this.formBuilder.group({
      notes_id: [null],
      notes_project: [null],
      notes: [null, [Validators.required]],
      allow_export: [null],
    });

    this.FilterForm = this.formBuilder.group({
      f_candidate_status_id: [null],
      f_strength: [null],
      f_gender: [null],
      f_diversity: [null],
      f_compensation: [null],
    });

    this.AddUserToSearchForm = this.formBuilder.group({
      user: [null],
    });
  }

  getCandidateFullList() {
    this.candidateService.listAll().subscribe({
      next: (response: any) => {
        this.candidateData = response.candidates.data;
        // this.candidateData = this.candidateData.filter((ele:any) => ele.is_active== 1) ;
        this.candidateData.forEach((ele: any) => {
          ele['name'] =
            ele.in_first_name +
            ' ' +
            ele.in_last_name +
            ' ' +
            (ele.current_company ? '[' + ele.current_company + ']' : '');
        });
      },
      error: (error) => {},
    });
  }
  download() {
    this.loadingService.setLoading(false);
  }
  onCandidateSearch(term: any) {
    let searchValue = term.term;
    if (searchValue.length >= 2) {
      let data = { search: searchValue };
      this.projectService.searchCandidateList(data).subscribe({
        next: (response: any) => {
          this.candidateData = response.candidates.data;
          // this.candidateData = this.candidateData.filter((ele:any) => ele.is_active== 1) ;
          this.candidateData.forEach((ele: any) => {
            ele['name'] =
              ele.in_first_name +
              ' ' +
              ele.in_last_name +
              ' ' +
              (ele.current_company ? '[' + ele.current_company + ']' : '');
          });
        },
        error: (err: any) => {},
      });
    }
  }

  resetBackendCandidateerror() {
    this.createCandidateErrorUrl = '';
    this.createCandidateErrorProjectId = '';
    this.createCandidateError = '';
    this.CreateCandidateForm.reset();
  }

  createCandidate() {
    this.submitted = true;
    // this.isdisable=true;

    if (this.CreateCandidateForm.invalid) {
      // this.notificationService.showError('Please Enter LinkedIn URL');
      // this.createCandidateError = 'Please Enter LinkedIn URL';
      this.isdisable = false;
      return;
    }
    if (
      this.CreateCandidateForm.controls['linkedInUrl'].value &&
      this.CreateCandidateForm.controls['candidateId'].value
    ) {
      this.eitherSelectError = true;
      return;
    }

    if (this.CreateCandidateForm.controls['linkedInUrl'].value) {
      this.createCandidateData = {
        url: this.CreateCandidateForm.controls['linkedInUrl'].value,
        project_id: this.projectId,
      };
      this.projectapicall = true;
      this.candidateService.create(this.createCandidateData).subscribe({
        next: (response: any) => {
          this.modalService.dismissAll();
          this.CreateCandidateForm.reset();
          this.notificationService.showSuccess(response.message);
          // window.location.reload();
          this.eitherSelectError = false;
          this.isdisable = false;
          this.submitted = false;
          this.projectapicall = false;
          this.router.navigate(['/candidate/' + response.candidate.id]);
          this.onLoad();
        },
        error: (error) => {
          this.createCandidateError = error.error.message;
          this.projectapicall = false;
          switch (error.status) {
            case 422:
              if (error.error.url) {
                this.createCandidateErrorUrl = error.error.url[0];
              }
              if (error.error.project_id) {
                this.createCandidateErrorProjectId = error.error.project_id[0];
              }
              break;
            default:
            //Nothing
          }
          // this.notificationService.showError(error.message);
        },
      });
    }

    if (this.CreateCandidateForm.controls['candidateId'].value) {
      this.createCandidateData = {
        CandidateId: this.CreateCandidateForm.controls['candidateId'].value,
        project_id: this.projectId,
      };
      this.projectapicall = true;
      this.candidateService
        .attachProject(
          this.createCandidateData.CandidateId,
          this.createCandidateData.project_id
        )
        .subscribe({
          next: (response: any) => {
            this.modalService.dismissAll();
            this.CreateCandidateForm.reset();

            this.notificationService.showSuccess(response.message);
            // window.location.reload();
            this.eitherSelectError = false;
            this.isdisable = false;
            this.submitted = false;
            this.projectapicall = false;
            this.router.navigate([
              '/candidate/' + this.createCandidateData.CandidateId,
            ]);
            this.onLoad();
          },
          error: (error) => {
            this.createCandidateError = error.error.message;
            this.projectapicall = false;
            switch (error.status) {
              case 422:
                if (error.error.url) {
                  this.createCandidateErrorUrl = error.error.url[0];
                }
                if (error.error.project_id) {
                  this.createCandidateErrorProjectId =
                    error.error.project_id[0];
                }
                break;
              default:
              //Nothing
            }
            // this.notificationService.showError(error.message);
          },
        });
    }
  }

  applyFilter(page: number = 1) {
    let apply_filter = '';
    this.pageNumber = page;

    if (this.FilterForm.controls['f_candidate_status_id'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'candidate_status_id=' +
        this.FilterForm.controls['f_candidate_status_id'].value;
    }

    if (this.filterStrengthId) {
      if (apply_filter != '') apply_filter += '&';

      apply_filter += 'strength=' + this.filterStrengthId;
    }

    if (this.FilterForm.controls['f_gender'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_gender=' + this.FilterForm.controls['f_gender'].value;
    }

    // if(this.FilterForm.controls['f_diversity'].value){
    //   if(apply_filter != "")
    //     apply_filter += "&";
    //   apply_filter += "search_diversity="+this.FilterForm.controls['f_diversity'].value;
    // }

    if (this.FilterForm.controls['f_compensation'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_compensation=' +
        this.FilterForm.controls['f_compensation'].value;
    }

    if (this.FilterForm.controls['f_diversity'].value != undefined) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_diversity=' + this.FilterForm.controls['f_diversity'].value;
    }

    this.currentAppliedFilter = apply_filter;

    let data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      apply_filter: apply_filter,
    };
    this.getCandidateList(data);
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.staticarrowsVisible = false;
    this.mouseOnColumnName = columnname;

    // this.currentSortedColumn = columnname;
    if (columnname == 'in_last_name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'status' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }

    // if (columnname == 'current_position' && this.sortedColumn != columnname) {
    //   this.staticarrowsVisible = true;
    // }
    // if (columnname == 'current_company' && this.sortedColumn != columnname) {
    //   this.staticarrowsVisible = true;
    // }
    // if (columnname == 'projects_count' && this.sortedColumn != columnname) {
    //   this.staticarrowsVisible = true;
    // }
    // if (columnname == 'in_personal_phone' && this.sortedColumn != columnname) {
    //   this.staticarrowsVisible = true;
    // }
    // if (columnname == 'in_personal_email' && this.sortedColumn != columnname) {
    //   this.staticarrowsVisible = true;
    // }
  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
  }

  editNoteDetail(EditId: any) {
    let NoteDetail = this.notesList.find((c: any) => c.id === EditId);
    if (NoteDetail.ms_mail_id) this.isEmailNotes = true;
    else this.isEmailNotes = false;
    this.UpdateNoteForm.controls['notes'].setValue(NoteDetail.note);
    this.UpdateNoteForm.controls['allow_export'].setValue(
      NoteDetail.allow_export
    );
    this.UpdateNoteForm.controls['notes_id'].setValue(EditId);
  }

  exportDocument() {
    this.projectService.exportProject(this.projectId).subscribe({
      next: (response: any) => {
        this.downloadUrl = response.url;
      },
      error: (error) => {},
    });
  }

  open(
    content: any,
    typeOfModal: string,
    EditId?: number,
    deleteName?: string,
    size = 'md'
  ) {
    if (typeOfModal == 'editNote') {
      this.editNoteSearchName = deleteName;
      this.editNoteDetail(EditId);
    }
    if (typeOfModal == 'CreateCandidates') {
      this.getCandidateFullList();
      this.CreateCandidateForm.controls['projectId'].setValue(
        this.projectDetail.name
      );
      this.CreateCandidateManuallyForm.controls['projectId'].setValue(
        this.projectDetail.name
      );
      this.submitted = false;
    }

    if (typeOfModal == 'deleteNote') {
      this.deleteNoteId = EditId;
    }
    if (typeOfModal == 'ExportDocument') {
      this.exportDocument();
      this.modalService
        .open(content, {
          ariaLabelledBy: 'modal-basic-title',
          size: size,
          windowClass: 'draggable-modal',
        })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          () => {
            //${this.getDismissReason(reason)}
            this.closeResult = `Dismissed `;
          }
        );
    }
    if (typeOfModal == 'deleteCandidate') {
      this.deleteCandidateName = deleteName;
      this.deleteCandidateId = EditId;
    }

    if (typeOfModal != 'ExportDocument') {
      this.modalService
        .open(content, {
          ariaLabelledBy: 'modal-basic-title',
          size: size,
          windowClass: 'draggable-modal',
        })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          () => {
            //${this.getDismissReason(reason)}
            this.closeResult = `Dismissed `;
          }
        );
    }
  }
  showCandidate(id: number) {
    this.router.navigate(['/candidate/' + id]);
  }

  // getCandidateList(data1: any = {}, data = {
  //   page: this.pageNumber,
  //   sort_field: this.currentSortedColumn,
  //   sort_method: this.currentSortedMethod,
  //   column_name: this.sortedColumn,
  //   apply_filter: this.currentAppliedFilter,
  //   search: this.searchvalue,
  // }, reset: boolean = false) {

  //   //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}
  //   if (this.searchvalue != '') {
  //     data.search = this.searchvalue
  //   }

  //   if (data1) {
  //     data.sort_field = data1.sort_field;
  //     data.sort_method = data1.sort_method || 'asc';
  //     data.column_name = data1.column_name;
  //   }

  //   if (!reset) {
  //     if (this.getCandidateSearchList) {
  //       this.searchvalue = data.search;
  //       this.getCandidateSearchList.candidates_search = data;
  //       this.storageService.setValue('listSettings', JSON.stringify(this.getCandidateSearchList));
  //     } else {
  //       this.storageService.setValue('listSettings', JSON.stringify({ candidates_search: data }));
  //     }
  //   } else {
  //     data.search = "";
  //     data.page = 1;
  //     data.sort_field = data1.sort_field;
  //     data.sort_method = data1.sort_method || 'asc';
  //     data.column_name = data1.column_name;
  //   }

  //   if (this.searchCandidateRank) {
  //     if (this.searchCandidateRank % 10 == 0) {
  //       data.page = Math.floor(this.searchCandidateRank / 10);
  //     }
  //     else {
  //       data.page = Math.floor(this.searchCandidateRank / 10) + 1
  //     }
  //   }

  //   this.projectService.candidateListByFilter(this.route.snapshot.params['id'], data).subscribe({
  //     next: (response) => {
  //       this.candidateList = response.candidates.data;
  //       if (this.searchCandidateRank) {
  //         let foundCandidate = this.candidateList.findIndex((e: any) => {
  //           return e.rank == this.searchCandidateRank;
  //         })
  //         this.candidateList[foundCandidate].checked = true;
  //         this.getCandidateNotes(this.candidateList[foundCandidate].id, this.candidateList[foundCandidate].in_first_name + ' ' + this.candidateList[foundCandidate].in_last_name);
  //       }
  //       if (this.candidateList[0] && !this.searchCandidateRank) {
  //         this.candidateList[0].checked = true;
  //         this.getCandidateNotes(this.candidateList[0].id, this.candidateList[0].in_first_name + ' ' + this.candidateList[0].in_last_name);
  //       }
  //       this.pageNumber = response.candidates.current_page;
  //       this.totalRows = response.candidates.total;
  //       this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
  //       if (data.sort_field) {
  //         this.iconVisible = false;
  //         this.staticarrowsVisible = true;
  //         this.currentSortedColumn = data.column_name;
  //         this.sortedColumn = data.column_name;
  //         if (data.sort_method === 'desc') {
  //           this.iconVisibleDesc = true;
  //           this.iconVisibleAsc = false;
  //         }
  //         if (data.sort_method === 'asc') {
  //           this.iconVisibleAsc = true;
  //           this.iconVisibleDesc = false;
  //         }
  //         this.sortfield = response.candidates.sort_field;
  //         this.sortmethod = response.candidates.sort_method;
  //       }
  //     },
  //     error: (error) => {
  //       this.notificationService.showError(error.message);
  //     },
  //   });
  // }

  getCandidateList(
    data1: any = {},
    data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      apply_filter: this.currentAppliedFilter,
      search: this.searchvalue,
    },
    reset: boolean = false
  ) {
    // Ensure sort_method is set properly
    data.sort_method = data.sort_method || 'asc';

    if (this.searchvalue != '') {
      data.search = this.searchvalue;
    }

    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method || 'asc';
      data.column_name = data1.column_name;
    }

    // Store sorting state
    this.currentSortedColumn = data.sort_field;
    this.currentSortedMethod = data.sort_method;

    // console.log(this.currentSortedColumn ,  this.currentSortedMethod ,"Called" )

    if (!reset) {
      if (this.getCandidateSearchList) {
        this.searchvalue = data.search;
        this.getCandidateSearchList.candidates_search = data;
        this.storageService.setValue(
          'listSettings',
          JSON.stringify(this.getCandidateSearchList)
        );
      } else {
        this.storageService.setValue(
          'listSettings',
          JSON.stringify({ candidates_search: data })
        );
      }
    } else {
      data.search = '';
      data.page = 1;
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method || 'asc';
      data.column_name = data1.column_name;
    }

    if (this.searchCandidateRank) {
      if (this.searchCandidateRank % 10 == 0) {
        data.page = Math.floor(this.searchCandidateRank / 10);
      } else {
        data.page = Math.floor(this.searchCandidateRank / 10) + 1;
      }
    }

    // const listSetting = localStorage.getItem('listSettings');
    // let parsedList: any;
    // if (listSetting) {
    //   parsedList = JSON.parse(listSetting).searches;
    // }

    // if (parsedList) {
    //   parsedList.sort_field = parsedList.sort_field || '';
    //   parsedList.sort_method = parsedList.sort_method || '';
    // }

    // if (parsedList) {
    //   if (parsedList.sort_method && parsedList.sort_method !== '') {
    //     if (
    //       (!this.lastSortedField && parsedList.sort_field) ||
    //       this.lastSortedField !== parsedList.sort_field ||
    //       this.lastSortedDirection !== parsedList.sort_method ||
    //       this.isSearchClicked ||
    //       this.isClearClicked ||
    //       this.resetButtonClicked
    //     ) {
    //       data.page = 1;
    //     }
    //   }
    // }

    this.projectService
      .candidateListByFilter(this.route.snapshot.params['id'], data)
      .subscribe({
        next: (response) => {
          this.candidateList = response.candidates.data;
          // if (
          //   this.isSearchClicked ||
          //   this.isClearClicked ||
          //   this.resetButtonClicked
          // ) {
          //   this.candidateList = response.candidates.data;
          //   console.log('condition 1')
          // } else {
          //   if (
          //     (!this.lastSortedField && parsedList.sort_field) ||
          //     (this.lastSortedField &&
          //       this.lastSortedField !== parsedList.sort_field) ||
          //     (this.lastSortedDirection &&
          //       this.lastSortedDirection !== parsedList.sort_method)
          //   ) {
          //   console.log('condition 2')
          //   this.candidateList = response.candidates.data;
          //   } else {
          //     console.log('default condition')
          //   this.candidateList = [...this.candidateList, ...response.candidates.data];
          //   }
          // }
  
          // if (parsedList && parsedList.sort_field) {
          //   this.lastSortedField = parsedList.sort_field;
          //   this.lastSortedDirection = parsedList.sort_method;
          // }
          // this.isSearchClicked = false;
          // this.isClearClicked = false;
          // this.resetButtonClicked = false;
  
          for (const resumeData of this.candidateList) {
            if (resumeData.resume_ids) {
              const resumePaths = resumeData.resume_paths.split('!');
              resumeData.resumePaths = resumePaths;
            }
          }

          if (this.searchCandidateRank) {
            let foundCandidate = this.candidateList.findIndex(
              (e: any) => e.rank == this.searchCandidateRank
            );
            if (foundCandidate !== -1) {
              this.candidateList[foundCandidate].checked = true;
              this.getCandidateNotes(
                this.candidateList[foundCandidate].id,
                this.candidateList[foundCandidate].in_first_name +
                  ' ' +
                  this.candidateList[foundCandidate].in_last_name
              );
            }
          }
          if (this.candidateList[0] && !this.searchCandidateRank) {
            this.candidateList[0].checked = true;
            this.getCandidateNotes(
              this.candidateList[0].id,
              this.candidateList[0].in_first_name +
                ' ' +
                this.candidateList[0].in_last_name
            );
          }
          this.pageNumber = response.candidates.current_page;
          this.totalRows = response.candidates.total;
          this.pager = this.pagerService.getPager(
            this.totalRows,
            this.pageNumber
          );
          if (data.sort_field) {
            this.iconVisible = false;
            this.staticarrowsVisible = true;
            this.currentSortedColumn = data.column_name;
            this.sortedColumn = data.column_name;
            if (data.sort_method === 'desc') {
              this.iconVisibleDesc = true;
              this.iconVisibleAsc = false;
            } else {
              this.iconVisibleAsc = true;
              this.iconVisibleDesc = false;
            }
            this.sortfield = response.candidates.sort_field;
            this.sortmethod = response.candidates.sort_method;
          }

          const candidatesData = response.candidates.data;
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }

  public searchData(value: any) {
    this.isSearchClicked = true;
    this.pageNumber = 1;
    this.getCandidateList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: value,
    });
  }

  public clearFilterForm() {
    this.FilterForm.reset();
    this.showFilterOptions = false;
    this.getCandidateList({});
    this.imageselected = false;
    this.isClearClicked = true;
    this.currentAppliedFilter = '';
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.searchCandidateRank = '';
    // this.applyFilter(page)
    this.getCandidateList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
    });
  }

  resetBackendNoteerror() {
    this.createNoteErrorNotes = '';
    this.createNoteErrorAllowexport = '';
    this.createNoteError = '';
    this.CreateNoteForm.reset();
  }

  addNote(candidateId: any = '') {
    this.submitted = true;
    if (this.CreateNoteForm.invalid) {
      return;
    }
    let data = {
      id: this.CandidateId,
      project_id: this.route.snapshot.params['id'],
      note: this.CreateNoteForm.controls['notes'].value,
      allow_export: this.CreateNoteForm.controls['allow_export'].value ? 1 : 0,
    };
    this.projectapicall = true;
    this.notesService.create(data).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(response.message);
        this.CreateNoteForm.reset();
        this.modalService.dismissAll();
        this.submitted = false;
        // window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getCandidateNotes(this.CandidateId);
        this.onLoad();
        this.formDirective.resetForm();
      },
      error: (error) => {
        this.createNoteError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.note) {
              this.createNoteErrorNotes = error.error.note[0];
            }
            if (error.error.allow_export) {
              this.createNoteErrorAllowexport = error.error.allow_export[0];
            }

            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      },
    });
  }

  deleteNoteDetail(candidateId: any = '') {
    this.notesService
      .deleteNote(this.CandidateId, this.deleteNoteId)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.getCandidateNotes(this.CandidateId);
        },
        error: (error) => {},
      });
  }

  resetBackendCandidateManuallyerror() {
    this.createCandidateManuallyError = '';
    this.CreateCandidateManuallyForm.reset();
    this.candidate_exists = [];
    // this.linkedin_candidate=true
  }

  updateNoteDetail(candidateId: any = '') {
    this.updatesubmitted = true;
    if (this.UpdateNoteForm.invalid) {
      return;
    }
    let data = {
      note: this.UpdateNoteForm.controls['notes'].value,
      allow_export: this.UpdateNoteForm.controls['allow_export'].value ? 1 : 0,
    };
    this.projectapicall = true;
    this.notesService
      .updateNote(
        this.CandidateId,
        this.UpdateNoteForm.controls['notes_id'].value,
        data
      )
      .subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
          this.modalService.dismissAll();
          this.updatesubmitted = false;
          this.projectapicall = false;
          this.getCandidateNotes(this.CandidateId, this.CandidateName);
          this.onLoad();
        },
        error: (error) => {
          this.updateNoteError = error.error.message;
          this.projectapicall = false;
          switch (error.status) {
            case 422:
              if (error.error.note) {
                this.updateNoteErrorNotes = error.error.note[0];
              }
              if (error.error.allow_export) {
                this.updateNoteErrorAllowexport = error.error.allow_export[0];
              }
              break;
            default:
            //Nothing
          }
          // this.notificationService.showError(error.message);
        },
      });
  }

  populateAddNotes(notes_project: any, notes: any, allow_export: any) {
    this.CreateNoteForm.controls['notes_project'].setValue(notes_project);
    this.CreateNoteForm.controls['notes'].setValue(notes);
    this.CreateNoteForm.controls['allow_export'].setValue(allow_export ? 1 : 0);
  }

  restrictAlphabet(evt: any) {
    const keys = evt.keyCode;
    if (keys < 48 || keys > 58) {
      return false;
    } else {
      return true;
    }
  }

  candidatechange(
    candidateid: any = '',
    changetype: any,
    selectedStatus?: any
  ) {
    let projectId = this.route.snapshot.params['id'];
    let responseMessage: string = '';
    // let data={
    //   project_id:projectId,
    //   // strength:0,
    //   rank:0,
    //   // candidate_status_id:0,
    //   // allow_export:0
    // };
    switch (changetype) {
      case 'candidateRank':
        let inputValue = (<HTMLInputElement>(
          document.getElementById('candidateRank_' + candidateid)
        )).value;
        this.projectRank = Number(inputValue);
        this.projectUpdate = {
          project_id: projectId,
          rank: this.projectRank,
        };
        responseMessage = 'Candidate rank updated';

        break;

      case 'candidateStatus':
        this.projectUpdate = {
          project_id: projectId,
          candidate_status_id: selectedStatus == 'null' ? null : selectedStatus,
        };

        break;
      case 'candidate_strength':
        this.projectUpdate = {
          project_id: projectId,
          strength: this.strengthId,
        };

        break;
      case 'allow_export':
        this.projectUpdate = {
          project_id: projectId,
          allow_export: this.allowExportcheck,
        };

        break;
    }

    this.candidateService
      .updateProjectStatus(candidateid, this.projectUpdate)
      .subscribe({
        next: (response: any) => {
          this.onLoad();
          // window.location.reload()
          this.notificationService.showSuccess(responseMessage);
        },
        error: (err: any) => {},
      });
  }
  showimage(data: string, strengthId: any, candidateId: any, changetype: any) {
    this.imagepath = data;
    // this.imageselected=true;
    this.strengthId = strengthId;
    this.candidatechange(candidateId, changetype);
  }
  showfilterimage(data: string, strengthId: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.filterStrengthId = strengthId;
  }
  allowExportchecked(event: any, candidateId: any, changetype: any) {
    event.target.checked
      ? (this.allowExportcheck = 1)
      : (this.allowExportcheck = 0);
    this.candidatechange(candidateId, changetype);
  }
  DeleteCandidates(candidateId: any = '') {
    this.candidateService.deleteCandidateData(candidateId).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.onLoad();
      },
      error: (err) => {},
    });
  }

  dropdownselect(evt: any) {
    this.selectedCandidateStatus = evt;
  }
  onCandidateSelected(selectedCandidate: any) {
    this.candidateList.forEach((candidate: any) => {
      if (candidate !== selectedCandidate) {
        candidate.checked = false; // Uncheck the previously checked radio button
      }
    });
    selectedCandidate.checked = true; // Check the currently selected radio button
    this.getCandidateNotes(
      selectedCandidate.id,
      selectedCandidate.in_first_name + ' ' + selectedCandidate.in_last_name
    );
  }

  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }

  getCandidateNotes(candidateId: any = '', candidateName?: any) {
    // let data = {project_id:this.route.snapshot.params['id'], sort_field:"updated_at", sort_method:"desc"};
    this.CandidateName = candidateName;
    let data = {
      id: candidateId,
      project_id: this.route.snapshot.params['id'],
      sort_field: 'updated_at',
      sort_method: 'desc',
    };
    this.CandidateId = candidateId;
    this.notesService.listByFilter(data).subscribe({
      next: (response: any) => {
        this.notesList = response.notes.data;
        this.notesList.forEach((element: any) => {
          element['initials'] = this.getInitials(element.creator.name);
        });
      },
      error: (error) => {},
    });
  }

  removeCandidate() {
    this.candidateService
      .detachProject(this.deleteCandidateId, this.projectId)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.onLoad();
        },
        error: (error) => {},
      });
  }
  addCandidate() {
    this.modalService.open(AppSubHeaderComponent, {
      size: 'xl',
      backdrop: 'static',
    });
  }

  // openSeracheditComponent(searchInfoid:any){
  //   this.modalService.open(AppSearchEditComponent,
  //     { size: 'xl', backdrop: 'static'
  //     });
  // }

  candidateTypeChange(value: number) {
    this.candidateTypeSelected = value;
    this.createCandidateErrorUrl = '';
    this.createCandidateErrorProjectId = '';
    this.createCandidateError = '';
    this.createCandidateManuallyError = '';
    this.candidate_exists = [];
    this.CreateCandidateForm.controls['projectId'].setValue(
      this.projectDetail.name
    );
    this.CreateCandidateManuallyForm.controls['projectId'].setValue(
      this.projectDetail.name
    );
  }

  public onSearchClick(value: any) {
    this.searchvalue = value;
    this.searchCandidateRank = '';
    this.pageNumber = 1;
    this.getCandidateList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: value,
    });
  }

  //  modal draggable
  // setMyStyles() {
  //   let styles = {
  //     'top': this.top + 'px',
  //     'left': this.left + 'px',
  //     position: 'absolute',
  //     cursor: 'move',
  //     width:'1000px'
  //   };
  //   return styles;
  // }

  // onMouseDown(event: MouseEvent) {
  //   this.dragging = true;
  //   this.initialX = event.clientX - this.left;
  //   this.initialY = event.clientY - this.top;
  // }

  // onMouseMove(event: MouseEvent) {
  //   if (this.dragging) {
  //     this.top = event.clientY - this.initialY;
  //     this.left = event.clientX - this.initialX;
  //   }
  // }

  // onMouseUp() {
  //   this.dragging = false;
  // }
  getProjectList() {
    //  this.projectselect?.clearModel();
    // this.showProjectDropdown=true;
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectList = response.projects.data;
        this.projectList = this.projectList.filter(
          (ele: any) => ele.project_status_id == 1
        );
        this.projectList.forEach((ele: any) => {
          ele['dropdown'] =
            ele?.name + (ele.client?.name ? ' [' + ele.client?.name + ']' : '');
        });
      },
      error: (error) => {},
    });
  }

  onProjectSearch(term: any) {
    let searchValue = term.term;

    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.projectService.searchProjectwiseList(data).subscribe({
        next: (response: any) => {
          this.projectList = response.projects.data;
          this.projectList = this.projectList.filter(
            (ele: any) => ele.project_status_id == 1
          );
          this.projectList.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name +
              (ele.client?.name ? ' [' + ele.client?.name + ']' : '');
          });
        },
        error: (err) => {},
      });
    }
  }

  createCandidateManually() {
    this.submitted = true;
    if (this.CreateCandidateManuallyForm.invalid) {
      return;
    }

    this.createCandidateManuallyData = {
      in_first_name: this.createCandidateManuallyForm['firstname'].value,
      in_last_name: this.createCandidateManuallyForm['lastname'].value,
      in_personal_email: this.createCandidateManuallyForm['email'].value,
      confirmed: this.createCandidateManuallyConfirmation ? 1 : 0,
      project_id: this.projectId,
    };

    // if(this.createCandidateManuallyForm['projectId'].value){
    //   this.createCandidateManuallyData.project_id = this.createCandidateManuallyForm['projectId'].value;
    // }

    this.projectapicall = true;
    this.candidateService
      .createManually(this.createCandidateManuallyData)
      .subscribe({
        next: (response) => {
          this.manually_submitted = response.status;
          if (response.status == 0) {
            this.projectapicall = false;
            this.createCandidateManuallyError = response.message;
            this.candidate_exists = response.candidates;
          } else {
            this.modalService.dismissAll();
            this.CreateCandidateManuallyForm.reset();
            this.notificationService.showSuccess(response.message);
            this.router.navigate(['/candidate/' + response.candidate.id]);
            this.projectapicall = false;
            this.submitted = false;
            // this.getCandidateList();
          }
        },
        error: (error) => {
          this.createCandidateError = error.error.message;
          this.projectapicall = false;
          switch (error.status) {
            case 422:
              if (error.error.url) {
                this.createCandidateErrorUrl = error.error.url[0];
              }
              if (error.error.projectId) {
                this.createCandidateErrorProjectId = error.error.projectId[0];
              }

              break;
            default:
            //Nothing
          }
          // this.notificationService.showError(error.message);
        },
      });
  }

  // edit search
  private getURLPattern(): string {
    return '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$';
  }

  EditProject(content: any, EditId: number) {
    // this.modalservice.open("UpdateProject");

    if (EditId != undefined || EditId != null) {
      this.projectService
        .getProjectData({ id: EditId })
        .subscribe((data: any) => {
          let response = data.project;
          this.ProjectdocId = data.project.id;
          this.getDocumentData();

          if (
            response.web_link_one !== 'null' &&
            response.web_link_one != null
          ) {
            this.UpdateProjectForm.controls['web_link_one'].setValue(
              response.web_link_one
            );
            this.showexternallink1 = true;
            this.weblink1 = response.web_link_one;
            this.editexternallink1 = false;
          } else {
            this.UpdateProjectForm.controls['web_link_one'].setValue('');
            this.showexternallink1 = false;
            this.weblink1 = '';
            this.editexternallink1 = true;
          }

          if (
            response.web_link_two !== 'null' &&
            response.web_link_two != null
          ) {
            this.UpdateProjectForm.controls['web_link_two'].setValue(
              response.web_link_two
            );
            this.showexternallink2 = true;
            this.weblink2 = response.web_link_two;
            this.editexternallink2 = false;
          } else {
            this.UpdateProjectForm.controls['web_link_two'].setValue('');
            this.showexternallink2 = false;
            this.weblink2 = '';
            this.editexternallink2 = true;
          }

          if (
            response.web_link_three !== 'null' &&
            response.web_link_three != null
          ) {
            this.UpdateProjectForm.controls['web_link_three'].setValue(
              response.web_link_three
            );
            this.showexternallink3 = true;
            this.weblink3 = response.web_link_three;
            this.editexternallink3 = false;
          } else {
            this.UpdateProjectForm.controls['web_link_three'].setValue('');
            this.showexternallink3 = false;
            this.weblink3 = '';
            this.editexternallink3 = true;
          }

          this.UpdateProjectForm.controls['projectId'].setValue(response.id);
          this.UpdateProjectForm.controls['name'].setValue(response.name);
          if (response.client && response.client.id)
            this.UpdateProjectForm.controls['clientname'].setValue(
              response.client.id
            );
          this.UpdateProjectForm.controls['official_start_date'].setValue(
            response.official_start_date
          );
          this.UpdateProjectForm.controls['official_end_date'].setValue(
            response.official_end_date
          );
          this.UpdateProjectForm.controls['city'].setValue(response.city);
          this.UpdateProjectForm.controls['state'].setValue(response.state);
          this.UpdateProjectForm.controls['postal_code'].setValue(
            response.postal_code
          );
          this.UpdateProjectForm.controls['street_address'].setValue(
            response.street_address
          );
          this.UpdateProjectForm.controls['description'].setValue(
            response.description
          );
          if (response.type && response.type.id)
            this.UpdateProjectForm.controls['type'].setValue(response.type.id);
          this.UpdateProjectForm.controls['project_status_id'].setValue(
            response.status.id
          );
          this.UpdateProjectForm.controls['user'].setValue(
            response.users.map((a: any) => a.id)
          );

          if (response.is_public === 1) {
            this.updateJobPostingForm.controls['projectId'].setValue(
              response.id
            );
            this.updateJobPostingForm.controls['name'].setValue(response.name);
            this.updateJobPostingForm.controls['city'].setValue(response.city);
            this.updateJobPostingForm.controls['state'].setValue(
              response.state
            );
            this.updateJobPostingForm.controls['description'].setValue(
              response.description
            );
            this.updateJobPostingForm.controls['project_status_id'].setValue(
              response.status.id
            );
            if (response.client && response.client.id) {
              this.updateJobPostingForm.controls['clientname'].setValue(
                response.client.id
              );
            }
            this.modalService
              .open(this.ispublic, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'xl',
              })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed `;
                }
              );
          } else {
            this.modalService
              .open(content, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'md',
              })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed `;
                }
              );
          }

          // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
          //   this.closeResult = `Closed with: ${result}`;
          // }, (reason) => {
          //   //${this.getDismissReason(reason)}
          //   this.closeResult = `Dismissed `;
          // });
        });
    }
  }

  openEditSerch(content: any, EditId: number) {
    this.EditProject(content, EditId);
    this.createProjectForm();
    // this.getClientList();
    this.getProjectTypeList();
    this.getStateList();
    this.getClientsList();
    this.getStatusofprojectList();
  }

  UpdateProjects() {
    this.submitted = true;

    // if (this.UpdateProjectForm.invalid) {
    //   return;

    //   }
    // if (this.UpdateProjectForm.errors && this.UpdateProjectForm.errors['endDateInvalid'] == true){
    //   return;
    // }

    // if (this.f['city'].errors || this.f['name'].errors || this.f['clientname'].errors){
    //   return;
    // }

    if (this.f['name'].errors || this.f['official_start_date'].errors) {
      return;
    }

    let userids = [];
    if (this.UpdateProjectForm.controls['user'].value)
      userids = this.UpdateProjectForm.controls['user'].value;

    let userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
    let currentUserId = this.storageService.getValue(StorageKey.currentUserid);
    if (
      (userRole == '2' &&
        userids.length > 0 &&
        !userids.includes(currentUserId)) ||
      (userRole == '2' && userids.length == 0)
    )
      userids.push(currentUserId);

    let UpdatedProjectDetails = {
      id: this.UpdateProjectForm.controls['projectId'].value,
      name: this.UpdateProjectForm.controls['name'].value,
      client_id: this.UpdateProjectForm.controls['clientname'].value,
      official_start_date:
        this.UpdateProjectForm.controls['official_start_date'].value,
      official_end_date:
        this.UpdateProjectForm.controls['official_end_date'].value,
      city: this.UpdateProjectForm.controls['city'].value,
      state: this.UpdateProjectForm.controls['state'].value,
      postal_code: this.UpdateProjectForm.controls['postal_code'].value,
      street_address: this.UpdateProjectForm.controls['street_address'].value,
      description: this.UpdateProjectForm.controls['description'].value,
      project_type_id: this.UpdateProjectForm.controls['type'].value,
      project_status_id: Number(
        this.UpdateProjectForm.controls['project_status_id'].value
      ),
      web_link_one: this.UpdateProjectForm.controls['web_link_one'].value,
      web_link_two: this.UpdateProjectForm.controls['web_link_two'].value,
      web_link_three: this.UpdateProjectForm.controls['web_link_three'].value,
      user_ids: userids,
      is_public: this.UpdateProjectForm.controls['is_public'].value ?? 0,
    };

    this.projectapicall = true;
    this.projectService
      .updateProjectData(UpdatedProjectDetails.id, UpdatedProjectDetails)
      .subscribe({
        next: (response: any) => {
          this.modalService.dismissAll();
          this.UpdateProjectForm.reset();
          this.notificationService.showSuccess(response.message);
          window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
          //  this.getProjectList();
        },
        error: (error: any) => {
          this.projectapicall = false;
          this.createProjectError = error.error.message;
          switch (error.status) {
            case 422:
              if (error.error.name) {
                this.createProjectErrorName = error.error.name[0];
              }
              if (error.error.client_id) {
                this.createProjectErrorClientname = error.error.client_id[0];
              }
              if (error.error.type) {
                this.createProjectErrorType = error.error.type[0];
              }
              if (error.error.project_status_id) {
                this.createProjectErrorProjectstatusid =
                  error.error.project_status_id[0];
              }
              if (error.error.official_start_date) {
                this.createProjectErrorOfficialstartdate =
                  error.error.official_start_date[0];
              }
              if (error.error.official_end_date) {
                this.createProjectErrorOfficialenddate =
                  error.error.official_end_date[0];
              }
              if (error.error.street_address) {
                this.createProjectErrorStreetaddress =
                  error.error.street_address[0];
              }
              if (error.error.postal_code) {
                this.createProjectErrorPostalcode = error.error.postal_code[0];
              }
              if (error.error.city) {
                this.createProjectErrorCity = error.error.city[0];
              }
              if (error.error.state) {
                this.createProjectErrorState = error.error.state[0];
              }
              if (error.error.description) {
                this.createProjectErrorDescription = error.error.description[0];
              }
              if (error.error.web_link_one) {
                this.createProjectErrorWeblinkone = error.error.web_link_one[0];
              }
              if (error.error.web_link_two) {
                this.createProjectErrorWeblinktwo = error.error.web_link_two[0];
              }
              if (error.error.web_link_three) {
                this.createProjectErrorWeblinkthree =
                  error.error.web_link_three[0];
              }

              break;

            default:

            //Nothing
          }
          //  this.notificationService.showError(error.message);
        },
      });
  }

  resetBackendProjecterror() {
    this.createProjectErrorName = '';
    this.createProjectErrorDescription = '';
    this.createProjectErrorClientname = '';
    this.createProjectErrorOfficialstartdate = '';
    this.createProjectErrorType = '';
    this.createProjectErrorProjectstatusid = '';
    this.createProjectErrorOfficialenddate = '';
    this.createProjectErrorPostalcode = '';
    this.createProjectErrorCity = '';
    this.createProjectErrorState = '';
    this.createProjectErrorWeblinkone = '';
    this.createProjectErrorWeblinktwo = '';
    this.createProjectErrorWeblinkthree = '';
    this.createProjectErrorState = '';
    this.CreatDocumentsErrorDocuments = '';
    this.CreatDocumentsErrorDocumentsname = '';
    this.createProjectError = '';
    this.UpdateProjectForm.reset();
  }

  // for documents
  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }

  UploadDocuments() {
    this.uploadsubmitted = true;
    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    // this.projectapicall3 = true;
    this.projectService.createDocument(this.ProjectdocId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        // this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        // this.projectapicall3 = false;
        this.uploadsubmitted = false;
        this.getDocumentData();
        this.UpdateProjectForm.controls['document'].setValue(null);
      },
      error: (error) => {
        // this.projectapicall3 = false;
        // this.CreatDocumentsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.document) {
              this.CreatDocumentsErrorDocuments = error.error.document[0];
            }

            if (error.error.name) {
              this.CreatDocumentsErrorDocumentsname = error.error.name[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      },
    });
  }

  deleteDocuments(deleteId: any) {
    this.projectService.deleteDocument(this.ProjectdocId, deleteId).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.getDocumentData();
      },
      error: (err: any) => {},
    });
  }

  getDocumentData() {
    this.projectService.getDocumentData(this.ProjectdocId).subscribe({
      next: (response) => {
        this.documentData = response.project.documents;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  // dropdown apis
  onClientSearch(term: any) {
    let searchValue = term.term;

    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.clientService.searchClientwiseList(data).subscribe({
        next: (response: any) => {
          this.clientsList = response.clients.data;
          this.clientsList = this.clientsList.filter(
            (ele: any) => ele.is_active == 1
          );
        },
        error: (err: any) => {},
      });
    }
  }

  getClientsList() {
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientsList = response.clients.data;
        this.clientsList = this.clientsList.filter(
          (ele: any) => ele.is_active == 1
        );
      },
      error: (error) => {},
    });
  }

  getStatusofprojectList() {
    if (this.statusofprojectList.length == 0) {
      this.projectService.statusofProjectList().subscribe({
        next: (response) => {
          this.statusofprojectList = response.project_statuses;
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    }
  }

  getClientList(searchTerm: any = '') {
    let data: any;

    if (searchTerm && searchTerm.length >= 2) data = { search: searchTerm };
    this.clientService.listByFilter(data).subscribe({
      next: (response) => {
        this.clientList = response.clients.data;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }
  getProjectTypeList() {
    this.projectService.listTypeAll().subscribe({
      next: (response) => {
        this.projectTypeList = response.project_types;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getProjectStatusList() {
    if (this.projectStatusList.length == 0) {
      this.projectService.listStatus().subscribe({
        next: (response) => {
          this.projectStatusList = response.project_statuses;
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    }
  }
  getStateList() {
    this.projectService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getUserProfile() {
    let currentTenant = this.storageService.getValue(
      StorageKey.currentTenantId
    );
    this.tenantlogin = currentTenant;
    //  if(currentTenant == '0'){
    //   return
    //  }

    this.userservice.userProfile(currentTenant).subscribe({
      next: (response) => {
        this.userprofile = response.user;
        this.imgSrc = response.user.avatar_path;

        //    let currentAvtar;
        //    currentAvtar =  this.imgSrc;
        //   if(currentAvtar == 'null'){
        //     this.avtarPath = false;
        //   }
        //  else{
        //       this.avtarPath = true;

        //     }

        response['initials'] = this.getInitials(response.user.name);
        this.userinitials = response.initials;

        var currentUsername = response.user.name;
        //  this.userShortname = currentUsername;
        //   const word = this.userShortname;
        //   if(word != null){
        //    const wordAsString = word.toString();
        //    this.getInitials(wordAsString);
        //  const initials = this.getInitials(wordAsString);
        //  this.sortname = initials;

        //   }
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getUserList() {
    this.userservice.listAll().subscribe({
      next: (response) => {
        this.userList = response.users.data;
      },
      error: () => {},
    });
  }

  onUserSearch(term: any) {
    let searchValue = term.term;

    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.userservice.searchUserwiseList(data).subscribe({
        next: (response: any) => {
          this.userList = response.users.data;
          this.userList = this.userList.filter(
            (ele: any) => ele.is_active == 1
          );
          this.userList.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name + (ele.user?.name ? ' [' + ele.user?.name + ']' : '');
          });
        },
        error: (err) => {},
      });
    }
  }

  adduserstosearch() {
    this.submitted = true;

    if (this.AddUserToSearchForm.invalid) {
      return;
    }

    if (this.AddUserToSearchForm.controls['user'].value) {
      // this.projectapicall = true;
      let userids = [];
      if (this.AddUserToSearchForm.controls['user'].value)
        userids.push(this.AddUserToSearchForm.controls['user'].value);

      if (
        this.userprofile &&
        this.userprofile?.role?.id == 2 &&
        !userids.includes(this.userprofile.id)
      )
        userids.push(this.userprofile.id);
      this.projectService
        .addUsersToSearch(this.projectId, { user_ids: userids })
        .subscribe({
          next: (response: any) => {
            this.modalService.dismissAll();
            this.AddUserToSearchForm.reset();
            this.notificationService.showSuccess(response.message);
            this.submitted = false;
            this.onLoad();
            this.projectapicall = false;
          },
          error: (error) => {
            this.createCandidateError = error.error.message;
            this.projectapicall = false;
            switch (error.status) {
              case 422:
                if (error.error.url) {
                  this.createCandidateErrorUrl = error.error.url[0];
                }
                if (error.error.project_id) {
                  this.createCandidateErrorProjectId =
                    error.error.project_id[0];
                }
                break;
              default:
              //Nothing
            }
            // this.notificationService.showError(error.message);
          },
        });
    }
  }

  Updatejobposting() {
    this.submitted = true;
    if (this.updateJobPostingForm.invalid) {
      return;
    }

    let UpdatedProjectDetails = {
      id: this.updateJobPostingForm.controls['projectId'].value,
      name: this.updateJobPostingForm.controls['name'].value,
      city: this.updateJobPostingForm.controls['city'].value,
      state: this.updateJobPostingForm.controls['state'].value,
      project_status_id: Number(
        this.updateJobPostingForm.controls['project_status_id'].value
      ),
      description: this.updateJobPostingForm.controls['description'].value,
      is_public: this.updateJobPostingForm.controls['is_public'].value ?? 1,
      client_id: this.updateJobPostingForm.controls['clientname'].value,
    };
    this.projectService
      .updateProjectData(UpdatedProjectDetails.id, UpdatedProjectDetails)
      .subscribe({
        next: (response: any) => {
          this.modalService.dismissAll();
          this.updateJobPostingForm.reset();
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          for (let i = 0; i < this.projectList.length; i++) {
            if (this.projectList[i].id == UpdatedProjectDetails.id) {
              this.projectList[i] = response.project;
              break;
            }
          }
        },
        error: (error: any) => {
          this.projectapicall = false;
          this.createProjectError = error.error.message;
          switch (error.status) {
            case 422:
              if (error.error.name) {
                this.createProjectErrorName = error.error.name[0];
              }
              if (error.error.project_status_id) {
                this.createProjectErrorProjectstatusid =
                  error.error.project_status_id[0];
              }
              if (error.error.city) {
                this.createProjectErrorCity = error.error.city[0];
              }
              if (error.error.state) {
                this.createProjectErrorState = error.error.state[0];
              }
              if (error.error.description) {
                this.createProjectErrorDescription = error.error.description[0];
              }
              break;

            default:
          }
        },
      });
  }
}
