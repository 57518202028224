import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../../common/storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  constructor(private httpClient: HttpClient,
      private storageService: StorageService) { }

      listAll(): Observable<any> {
          return this.httpClient.get(`${environment.apiUrl}users?sort_field=name&sort_method=asc&role_id=1`);
        }

        create(data: any): Observable<any> {
          return this.httpClient.post(`${environment.apiUrl}users`, data);
        }

        updateAdminData(id: number, data: any): Observable<any> {
          return this.httpClient.post(`${environment.apiUrl}users/` + id, data);
        }
      
        deleteAdminData(id: number): Observable<any> {
          return this.httpClient.delete(`${environment.apiUrl}users/` + id);
        }
}
