<div class="top-row d-flex justify-content-between">
  <div class="top-left-sec w-50">
    <h3 class="d-inline-block align-middle m-0">Super Admins</h3>
  <div
    class="d-inline-block align-middle m-0 ps-3 pt-1"
    style="cursor: pointer"
    title="reset"
  >
    <em class="fa fa-refresh"></em>
  </div>
  <div class="create d-inline-block">
    <!-- Button trigger modal -->
    <button
      type="button"
      class="border-0 bg-transparent"
      (click)="openAddModal(adminModal)"
    >
      <a class="blue-btn ms-4">+ Add</a>
    </button>
    </div>
    <div class="modal-content">

    </div>
  </div>
</div>
<ng-template #adminModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="
        modal.dismiss('Cross click');
        this.submitted = false;"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="AdminForm" >
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserError">
      {{createUserError}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorName">
      {{createUserErrorName}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorEmail">
      {{createUserErrorEmail}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorPassword">
        {{createUserErrorPassword}}</div>
    <div class="modal-body">
      <!-- Name Field -->
      <div class="mb-3">
        <label for="name">Name</label>
        <span class="text-danger">*</span>
        <input
          type="text"
          id="name"
          placeholder="Enter name"
          formControlName="name"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
        />
        <div *ngIf="submitted && f['name'].errors" class="text-danger">
          Name is required.
        </div>
      </div>
  
      <!-- Email Field -->
      <div class="mb-3">
        <label for="email">Email</label>
        <span class="text-danger">*</span>
        <input
          type="email"
          id="email"
          placeholder="Enter email"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
        />
        <div *ngIf="submitted && f['email'].errors" class="text-danger">
          <span *ngIf="f['email'].errors?.required">Email is required.</span>
          <span *ngIf="f['email'].errors?.pattern">Invalid Email Address.</span>
        </div>
      </div>
  
      <!-- Password Field -->
      <div class="password-container mb-3">
        <label for="password">Password</label>
        <span *ngIf="!editMode" class="text-danger">*</span>
        <div class="password-container d-flex align-items-center">
          <input
            [type]="showPassword ? 'text' : 'password'"
            id="password"
            class="form-control"
            placeholder="Enter password"
            formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
          />
          <em
            class="fa"
            [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"
            (click)="togglePassword()"
            style="cursor: pointer; margin-left: 10px"
          ></em>
        </div>
        <small class="text-primary" *ngIf="editMode">Leave this field empty unless you want to change it.</small>
        <div *ngIf="submitted && f['password'].errors" class="text-danger">
          <span *ngIf="f['password'].errors?.required">Password is required.</span>
          <span *ngIf="f['password'].errors?.minlength || f['password'].errors?.maxlength">
            Password must be between 6 - 16 characters.
          </span>
          <span *ngIf="f['password'].errors?.pattern">Password must contain atleast one: lowercase, uppercase, digit and special characters.</span>
        </div>
      </div> 
       <!-- Active-Inactive Dropdown -->
      <div class="mb-3" *ngIf="editMode">
        <label for="is_active">Status</label><span class="text-danger">*</span>
        <select id="is_active" formControlName="is_active" class="form-select">
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>
    </div>

            <!-- Modal Footer -->
    <div class="modal-footer">
      <button type="button" class="filter" (click)="modal.close('Save click')" >Cancel</button>
      <button type="submit" class="blue-btn" (click)="editMode ? UpdateAdmin() : createAdmin()">Save</button>
    </div>
  </form>
  
</ng-template>

<div class="bottom-table">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" style="width:35%" >
          Name
        </th>
        <th scope="col" style="width:40%" >
          Email
        </th>

        <th scope="col" style="width:10%;text-align: center;">
          Status
        </th>
        <th scope="col" style="width:5%">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of superAdminList">
        <td> <span class="name">
            <div class="form-check">
              <label class="form-check-label" for="flexCheckDefault">
                <button class="profile-name" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                  style="padding: 0px;">
                  <img  src="" alt="" />
                  <span style="padding-left: 0px;">{{user.initials}}</span>
                </button>
                <span style="margin-left: 20px;">{{user.name}}</span>
              </label>
            </div>
          </span> </td>
        <td style="text-align: left;">{{user.email}}</td>
        <td style="text-align: center;">
          <span *ngIf="user.is_active === 1" class="badge text-bg-success" >Active</span>
          <span *ngIf="user.is_active === 0" class="badge text-bg-warning" >Inactive</span>
        </td>
        <td>
          <div style="cursor: pointer;">
            <a ><em class="bi bi-pencil-square me-1" title="Edit" (click)="openEditModal(adminModal, user)"></em></a>
            <a (click)="openDeleteModal(content,user.id,user)" [hidden]="UserId == user.id"><em class="bi bi-trash me-1"
              title="Delete"></em></a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="pagination-main">
  <label for="" class="list-totalrecords">Total Records: {{superAdminList.length}}</label>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <p>
          Are you sure you want to delete ?
        </p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger dangerbtn" (click)="modal.close('Ok click')">Delete</button>
  </div>
</ng-template>