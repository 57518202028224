import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuperAdminService } from 'src/app/core/services/superadmin/super-admin.service';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css']
})
export class SuperAdminComponent {
  AdminForm!: FormGroup
  createAdminData: any;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  showPassword = false;
  showConfirmPassword = false;
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  dropimg: boolean = false;
  submitted: boolean = false;
  editMode = false;
  UserId!: number;
  currentUserId: any;
  modalTitle = 'Add Super Admin';
  createUserError: string = ""
  createUserErrorName: any;
  createUserErrorEmail: any;
  createUserErrorPassword: any;
  createUserErrorIsactive: any;
  superAdminList: any = [];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;
  selectDeletedUser: string | undefined;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private superadminservice: SuperAdminService,
  ){
    this.generateForm();
  //   this.AdminForm = this.fb.group({
  //     name: ['', [Validators.required]],
  //     email: ['', [Validators.required, Validators.email]],
  //     password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
  //     confirmPassword: ['', Validators.required],
  //   },{ validators: this.passwordsMatch }
  // );
  }
  
  ngOnInit(){
    this.getSuperAdminList({})
    // this.generateForm();
    this.getCurrentUserId();
  }

  getCurrentUserId() {
    const storedUser = localStorage.getItem('currentUser'); // Assuming user data is stored as JSON
    if (storedUser) {
      this.currentUserId = JSON.parse(storedUser).id; // Adjust based on your stored structure
      // console.log('currentUser',this.currentUserId)
    }
  }

  getSuperAdminList(data: any){

    this.superadminservice.listAll().subscribe({
      next: (response) => {
        this.superAdminList = response.users;
        // console.log('super admin list', this.superAdminList.map((user:any) => user.name));
        this.superAdminList.forEach((element: any) => {
          element["initials"] = this.getInitials(element.name);
        });
      },
      error: (error) => {
        console.log(error)
        // this.notificationService.showError(error.message);
      },});
  }

  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();
      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }
    return initials;
  }

  get f() {
    return this.AdminForm.controls as { [key: string]: any };
  }

  passwordsMatch(control: AbstractControl) {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { mustMatch: true };
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  generateForm(isEditMode = false) {
    this.AdminForm = this.fb.group({
      UserId: [null], // Add UserId for Edit Mode
      role_id: [1],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [null, isEditMode ? [] :[Validators.required, Validators.minLength(6), Validators.maxLength(16), Validators.pattern(this.passwordPattern)]],
      is_active: ['1'],
    })
  }

  createAdmin() {
    this.submitted = true;
    
    if (this.AdminForm.invalid) {
      return;
    }
  
    this.createAdminData = {
      name: this.AdminForm.get('name')?.value,
      email: this.AdminForm.get('email')?.value,
      password: this.AdminForm.get('password')?.value,
      role_id: 1,
    };
  
  
    this.superadminservice.create(this.createAdminData).subscribe({
      next: (response) => {
        console.log('object created', response);
        this.modalService.dismissAll();
        this.AdminForm.reset();
        // this.notificationService.showSuccess(response.message);
        this.getSuperAdminList({});
        this.submitted = false;
      },
      error: (error) => {
        console.log(error)
        
        if (error.error) {
          this.createUserError = error.error.message;
          if (error.status === 422) {
            this.createUserErrorName = error.error.name?.[0] || '';
            this.createUserErrorEmail = error.error.email?.[0] || '';
            this.createUserErrorPassword = error.error.password?.[0] || '';
          }
        }
      },
    });
  }

  UpdateAdmin() {
    this.submitted = true;
  
    if (this.AdminForm.invalid) {
      return;
    }
  
    let updatedUserDetails = {
      id: this.AdminForm.get('UserId')?.value,
      name: this.AdminForm.get('name')?.value,
      email: this.AdminForm.get('email')?.value,
      password: this.AdminForm.get('password')?.value, // Only send if not empty
      is_active: this.AdminForm.get('is_active')?.value,
    };
  
    const formData: FormData = new FormData();
    formData.append('id', updatedUserDetails.id);
    formData.append('name', updatedUserDetails.name);
    formData.append('email', updatedUserDetails.email);
    formData.append('is_active', updatedUserDetails.is_active);
  
    if (updatedUserDetails.password) {
      formData.append('password', updatedUserDetails.password);
    }
  
    this.superadminservice.updateAdminData(updatedUserDetails.id, formData).subscribe({
      next: (response) => {
        // console.log('User updated successfully', response);
        this.modalService.dismissAll();
        this.AdminForm.reset();
        this.submitted = false;
        this.getSuperAdminList({}); // Refresh user list
      },
      error: (error) => {
        console.error(error);
        this.createUserError = error.error?.message || 'Error updating user';
      }
    });
  }
  
  deleteAdmin(userId: number) {
      this.superadminservice.deleteAdminData(userId).subscribe({
        next: () => {
          // console.log('User deleted successfully');
          this.getSuperAdminList({}); // Refresh list after deletion
        },
        error: (error) => {
          console.error(error);
          alert('Failed to delete user');
        }
      });
  }
  

  openAddModal(content: any) {
    this.editMode = false;
    this.modalTitle = 'Add Super Admin';
    this.AdminForm.reset({ is_active: '1' });
    this.generateForm(false); 
    this.modalService.open(content, { size: 'md', backdrop: 'static' });
  }

  
  openEditModal(content: any, adminData: any) {
    this.editMode = true;
    this.modalTitle = 'Edit Super Admin';
    this.generateForm(true);
    this.createUserErrorEmail = null;

    this.AdminForm.patchValue({
      UserId: adminData.id,
      name: adminData.name,
      email: adminData.email,
      password: '', // Keep password empty for security
      role_id: adminData.role_id,
      is_active: adminData.is_active ? '1' : '0', // Convert boolean to string
    });

    this.modalService.open(content, { size: 'md', backdrop: 'static' });
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedUser = value.name;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
        this.deleteAdmin(deleteId);
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

}
