import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  futureDateValidator,
  endDateValidator,
} from '../../candidate/custom-validators';
import { birthDateNotFuture } from '../../../shared/components/date.validators';

@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.css'],
})
export class CandidateProfileComponent implements OnInit {
  tooltipContent: string =
    'This is your login ID. To change it, use the "Edit Login Details" option.';

  production_environment: boolean = true;
  uat_environment: boolean = false;

  files2: any;
  fileName2: any;
  filePath2: any;
  files: any;
  fileName: any;
  filePath: any;
  url: any;
  projectapicall: boolean = false;
  candidate_id: any;
  candidateData: any;
  jobsapplied: any = [];
  documentData: any = [];
  certificateList: any = [];
  educationData: any = [];
  experienceData = [];
  closeResult: string = '';
  CreateCandidateDetailsForm: FormGroup;
  submitted: boolean = false;
  stateData: any = [];
  CreateCandidateDetailsError: any;
  CreateCandidateDetailsErrorFirstname: any;
  CreateCandidateDetailsErrorLastname: any;
  CreateCandidateDetailsErrorGender: any;
  CreateCandidateDetailsErrorInbirthdate: any;
  CreateCandidateDetailsErrorCurrentcompany: any;
  CreateCandidateDetailsErrorCurrentposition: any;
  CreateCandidateDetailsErrorCustomdiversity: any;
  CreateCandidateDetailsErrorCustomcompensationdetails: any;
  CreateCandidateDetailsErrorLanguageids: any;
  CreateCandidateDetailsErrorLinkedinurl: any;
  CreateContactsDetailsError: any;
  CreateContactsDetailsErrorInpersonalphone: any;
  CreateContactsDetailsErrorInpersonalemail: any;
  CreateContactsDetailsErrorInworkphone: any;
  CreateContactsDetailsErrorInworkemail: any;
  CreateContactsDetailsErrorisInpersonalphonepreffered: any;
  CreateContactsDetailsErrorisInpersonalemailpreffered: any;
  CreateContactsDetailsErrorisInworkphonepreffered: any;
  CreateContactsDetailsErrorisInworkemailpreffered: any;
  CreateContactsDetailsErrorInstate: any;
  CreateContactsDetailsErrorIncity: any;
  CreateContactsDetailsErrorIncountryfullname: any;
  AddEducationForm!: FormGroup;
  AddCertificateForm!: FormGroup;
  CreateEducationDetailsErrorDegreename: any;
  CreateEducationDetailsErrorSchool: any;
  CreateEducationDetailsErrorStartdate: any;
  CreateEducationDetailsErrorEnddate: any;
  UpdateEducationDetailsError: any;
  CreateEducationDetailsError: any;
  deleteName: any;
  deleteEducationId: any;
  UpdateEducationDetailsErrorDegreename: any;
  UpdateEducationDetailsErrorSchool: any;
  UpdateEducationDetailsErrorStartdate: any;
  UpdateEducationDetailsErrorEnddate: any;
  CreateCertificateDetailsError: any;
  CreateCertificateDetailsErrorName: any;
  CreateCertificateDetailsErrorAuthority: any;
  CreateCertificateDetailsErrorStartdate: any;
  CreateCertificateDetailsErrorEnddate: any;
  UpdateCertificateDetailsError: any;
  UpdateCertificateDetailsErrorName: any;
  UpdateCertificateDetailsErrorAuthority: any;
  UpdateCertificateDetailsErrorStartdate: any;
  UpdateCertificateDetailsErrorEnddate: any;
  deleteCertificateId: any;
  AddDocumentForm!: FormGroup;
  CreatDocumentsError: any;
  CreatDocumentsErrorDocument: any;
  CreatDocumentsErrorDescription: any;
  deleteDocumentId: any;
  UpdateDocumentsErrorDescription: any;
  UpdateDocumentsErrorDocument: any;
  UpdateDocumentsError: any;

  applyjobData: any;
  UpdateUrlForm: FormGroup;
  checkLinkedinURL: any;
  @ViewChild('addURLModal', { static: false }) addURLModal: any;
  @ViewChild('existing_job', { static: true }) existing_job: any;

  updateURLsuccessMsg: any;

  addLinkedinURLError: any;
  linkedinurlError: any;
  doctyoeerror: any;

  jobId: any;
  jobName: any;
  jobCity: any;
  jobState: any;

  jobapplyForm: FormGroup;

  showlinkedininput: boolean = false;
  applysuccessMsg: any;
  showuploadButton: boolean = false;

  applyJobError: any;
  project_idError: any;
  urlError: any;
  tenant_idError: any;
  uploadresumesuccess: any;
  currentUser: any;

  isResumeOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
  ];

  constructor(
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private CandidatejobsService: CandidatejobsService,
    private storageService: StorageService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    let loginUserDetail: any = this.storageService.getValue(
      StorageKey.currentUser
    );
    this.currentUser = JSON.parse(loginUserDetail);
    if (this.currentUser.role.name == 'Candidate') {
      this.router.navigate(['candidate-profile']);
    } else {
      this.router.navigate(['dashboard']); // Navigate to the dashboard page
    }

    this.candidate_id = this.storageService.getValue('candidate_id');

    this.CreateCandidateDetailsForm = this.formBuilder.group({
      id: [null],
      // firstname: [null, Validators.required],
      // lastname: [null, Validators.required],
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      gender: [null],
      current_company: [null],
      current_position: [null],
      // in_birth_date: [null],
      in_birth_date: [null, [birthDateNotFuture()]],
      in_state: [null],
      in_city: [null],
      in_work_phone: [null],
      in_personal_email: [null],
      in_personal_phone: [null],
      in_work_email: [null],
      country: [null],
      is_in_personal_phone_preffered: [null],
      is_in_personal_email_preffered: [null],
      is_in_work_phone_preffered: [null],
      is_in_work_email_preffered: [null],
      language_ids: [null],
      linkedin_url: [null],
      custom_diversity: [null],
      custom_compensation_details: [null],
      source: [null],
    });

    // this.AddEducationForm = this.formBuilder.group({
    //   degree_name: [null, Validators.required],
    //   school: [null],
    //   start_date: [null],
    //   end_date: [null],
    //   education_id: [null]
    // })

    this.AddEducationForm = this.formBuilder.group(
      {
        degree_name: [null, Validators.required],
        school: [null],
        start_date: [null, futureDateValidator()],
        end_date: [null],
        education_id: [null],
      },
      { validators: endDateValidator('start_date', 'end_date') }
    );

    // this.AddCertificateForm = this.formBuilder.group({
    //   name: [null, Validators.required],
    //   authority: [null, Validators.required],
    //   start_date: [null],
    //   end_date: [null],
    //   certificateId: [null]
    // })

    this.AddCertificateForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        authority: [null, Validators.required],
        start_date: [null, futureDateValidator()],
        end_date: [null],
        certificateId: [null],
      },
      { validators: endDateValidator('start_date', 'end_date') }
    );

    this.AddDocumentForm = this.formBuilder.group({
      document: [null, Validators.required],
      description: [null, [Validators.minLength(0), Validators.maxLength(15)]],
      documentId: [null],
      is_resume: ["0"],
    });

    this.UpdateUrlForm = this.formBuilder.group({
      linkedinurl: [null],
    });

    this.jobapplyForm = this.formBuilder.group({
      linkedinurl: [null],
      tenantid: [null],
    });
  }

  get f() {
    return this.CreateCandidateDetailsForm.controls;
  }
  get educationDetails() {
    return this.AddEducationForm.controls;
  }
  get certificateDetails() {
    return this.AddCertificateForm.controls;
  }
  get documentDetails() {
    return this.AddDocumentForm.controls;
  }
  get job() {
    return this.jobapplyForm.controls;
  }

  ngOnInit(): void {
    this.onload();
    this.getapplied_job();

    // const job_name = localStorage.getItem('job_name');
    // const job_id = localStorage.getItem('job_id');
    // const job_city_name = localStorage.getItem('job_city_name');
    // const job_state_name = localStorage.getItem('job_state_name');

    // console.log(job_name, job_id, job_city_name, job_state_name);
    // console.log("job_id:", job_id);

    // if (job_id) {
    //   // Open the modal only if job_id exists
    //   this.modalService.open(this.existing_job, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   }, () => {
    //     this.closeResult = `Dismissed `;
    //   });
    // }

    this.route.queryParams.subscribe((params) => {
      this.jobId = params['job_id'];
      this.jobName = params['job_name'];
      this.jobCity = params['job_city_name'];
      this.jobState = params['job_state_name'];
    });

    if (this.jobId && this.jobName && this.jobCity && this.jobState) {
      this.modalService
        .open(this.existing_job, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
        })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          () => {
            this.closeResult = `Dismissed `;
          }
        );
    }
  }

  get validation() {
    return this.UpdateUrlForm.controls;
  }

  onload() {
    this.CandidatejobsService.candidateDetail(this.candidate_id).subscribe({
      next: (response: any) => {
        this.candidateData = response.candidate;
        this.experienceData = response.candidate.experiences;
        this.educationData = response.candidate.qualifications;
        this.certificateList = response.candidate.certifications;
        this.documentData = response.candidate.documents;
        this.url = this.candidateData.in_profile_pic_url;
        if (this.url == null) {
          console.log('URL is null');
        } else if (this.url != null) {
          console.log(`URL is set to: ${this.url}`);
        }

        this.checkLinkedinURL = response.candidate.in_public_identifier;

        // if (this.checkLinkedinURL == null) {

        //   this.modalService.open(this.addURLModal, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
        //     this.closeResult = `Closed with: ${result}`;
        //   }, () => {
        //     this.closeResult = `Dismissed `;
        //   });

        // } else {
        //   console.log(this.checkLinkedinURL, "URL is Present")
        //   // this.modalService.open(this.addURLModal, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
        //   //   this.closeResult = `Closed with: ${result}`;
        //   // }, () => {
        //   //   this.closeResult = `Dismissed `;
        //   // });
        // }

        if (
          this.candidateData.documents &&
          this.candidateData.documents.length > 0
        ) {
          // console.log("document id present");
          this.showuploadButton = false;
        } else {
          this.showuploadButton = true;
          // console.log("document not present");
        }
      },
      error: (error) => {
        // this.notificationService.showError(error.message);
      },
    });
  }

  getapplied_job() {
    this.CandidatejobsService.getapplied_job(this.candidate_id).subscribe({
      next: (response: any) => {
        this.applyjobData = response.jobs;
      },
      error: (error) => {
        // this.notificationService.showError(error.message);
      },
    });
  }

  // getCandidate_details() {

  //   this.modalService.open(this.existing_job, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, () => {
  //     this.closeResult = `Dismissed `;
  //   });

  // }

  attachLogoFiles(event: any) {
    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      const formData: FormData = new FormData();
      formData.append('name', this.fileName2);
      formData.append('profile_pic', this.files2);
      this.projectapicall = true;
      this.CandidatejobsService.updateCandidateData(
        this.candidate_id,
        formData
      ).subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          this.CreateCandidateDetailsForm.reset();
          this.onload();
          window.location.reload();
        },
        error: (error) => {
          this.projectapicall = false;
          this.CreateCandidateDetailsError = error.error.message;
          switch (error.status) {
            case 422:
              if (error.error.in_first_name) {
                this.CreateCandidateDetailsErrorFirstname =
                  error.error.in_first_name[0];
              }
              if (error.error.in_last_name) {
                this.CreateCandidateDetailsErrorLastname =
                  error.error.in_last_name[0];
              }
              if (error.error.in_gender) {
                this.CreateCandidateDetailsErrorGender =
                  error.error.in_gender[0];
              }
              if (error.error.in_birth_date) {
                this.CreateCandidateDetailsErrorInbirthdate =
                  error.error.in_birth_date[0];
              }
              if (error.error.current_company) {
                this.CreateCandidateDetailsErrorCurrentcompany =
                  error.error.current_company[0];
              }
              if (error.error.current_position) {
                this.CreateCandidateDetailsErrorCurrentposition =
                  error.error.current_position[0];
              }
              if (error.error.custom_diversity) {
                this.CreateCandidateDetailsErrorCustomdiversity =
                  error.error.custom_diversity[0];
              }
              if (error.error.custom_compensation_details) {
                this.CreateCandidateDetailsErrorCustomcompensationdetails =
                  error.error.custom_compensation_details[0];
              }
              if (error.error.language_ids) {
                this.CreateCandidateDetailsErrorLanguageids =
                  error.error.language_ids[0];
              }
              if (error.error.linkedin_url) {
                this.CreateCandidateDetailsErrorLinkedinurl =
                  error.error.linkedin_url[0];
              }
              break;
            default:
            //Nothing
          }
        },
      });
    }
  }

  searchPeopleInCompany() {
    let candidateStorage: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    candidateStorage = JSON.parse(candidateStorage);
    //  candidateStorage.candidates.search = this.candidateData.current_company;
    this.storageService.setValue(
      'listSettings',
      JSON.stringify({
        candidates: { apply_filter: this.candidateData.current_company },
      })
    );
    candidateStorage.candidates.apply_filter =
      'search_current_company=' + this.candidateData.current_company;
    this.storageService.setValue(
      StorageKey.listSettings,
      JSON.stringify(candidateStorage)
    );
    this.router.navigate(['/candidate-list']);
  }

  open(
    content: any,
    typeOfModal: string,
    EditId?: number,
    deleteName?: string,
    size = 'md'
  ) {
    if (typeOfModal == 'UpdateCandidateDetails') {
      this.OpenCandidateDetails();
    }

    if (typeOfModal == 'UpdateContactDetails') {
      this.OpenContactDetails();
    }

    if (typeOfModal == 'AddEducation') {
      this.AddEducationForm.reset();
      this.submitted = false;
    }

    if (typeOfModal == 'UpdateEducation') {
      this.EditEducationDetails(EditId);
    }

    if (typeOfModal == 'deleteEducation') {
      this.deleteName = deleteName;
      this.deleteEducationId = EditId;
    }

    if (typeOfModal == 'AddCertificate') {
      this.AddCertificateForm.reset();
      this.submitted = false;
    }

    if (typeOfModal == 'deleteCerticate') {
      this.deleteName = deleteName;
      this.deleteCertificateId = EditId;
    }

    if (typeOfModal == 'EditCertificate') {
      this.EditCertificate(EditId);
    }

    if (typeOfModal == 'UploadDocument') {
      this.submitted = false;
      this.AddDocumentForm.reset();
    }

    if (typeOfModal == 'deleteDocument') {
      this.deleteName = deleteName;
      this.deleteDocumentId = EditId;
    }

    if (typeOfModal == 'EditDocument') {
      this.submitted = false;
      this.EditDocument(EditId);
    }

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        () => {
          this.closeResult = `Dismissed `;
        }
      );
  }

  OpenCandidateDetails() {
    this.getStates();
    this.CreateCandidateDetailsForm.controls['id'].setValue(
      this.candidateData.id
    );
    this.CreateCandidateDetailsForm.controls['firstname'].setValue(
      this.candidateData.in_first_name
    );
    this.CreateCandidateDetailsForm.controls['lastname'].setValue(
      this.candidateData.in_last_name
    );
    // this.CreateCandidateDetailsForm.controls['gender'].setValue(this.candidateData.in_gender);

    if (
      this.candidateData.in_gender !== null &&
      this.candidateData.in_gender !== undefined
    ) {
      const genderValue =
        this.candidateData.in_gender.toLowerCase() === 'male'
          ? 'Male'
          : this.candidateData.in_gender.toLowerCase() === 'female'
          ? 'Female'
          : 'Other';
      this.CreateCandidateDetailsForm.controls['gender'].setValue(genderValue);
    }

    this.CreateCandidateDetailsForm.controls['in_birth_date'].setValue(
      this.candidateData.in_birth_date
    );
    this.CreateCandidateDetailsForm.controls['current_company'].setValue(
      this.candidateData.current_company
    );
    this.CreateCandidateDetailsForm.controls['current_position'].setValue(
      this.candidateData.current_position
    );
    this.CreateCandidateDetailsForm.controls['in_state'].setValue(
      this.candidateData.in_state
    );
    this.CreateCandidateDetailsForm.controls['in_city'].setValue(
      this.candidateData.in_city
    );
    this.CreateCandidateDetailsForm.controls['country'].setValue(
      this.candidateData.in_country_full_name
    );
    this.CreateCandidateDetailsForm.controls['linkedin_url'].setValue(
      this.candidateData.linkedin_url
    );
  }

  // Update Contact Summary
  UpdateCandidatesDetails() {
    this.submitted = true;
    if (this.CreateCandidateDetailsForm.invalid) {
      return;
    }

    if (this.CreateCandidateDetailsForm.controls['gender'].value === 'null') {
      this.CreateCandidateDetailsForm.controls['gender'].setValue('');
    }

    let updatedCandidate = {
      id: this.CreateCandidateDetailsForm.controls['id'].value,
      in_first_name:
        this.CreateCandidateDetailsForm.controls['firstname'].value,
      in_last_name: this.CreateCandidateDetailsForm.controls['lastname'].value,
      in_gender: this.CreateCandidateDetailsForm.controls['gender'].value,
      in_birth_date:
        this.CreateCandidateDetailsForm.controls['in_birth_date'].value,
      current_company:
        this.CreateCandidateDetailsForm.controls['current_company'].value,
      current_position:
        this.CreateCandidateDetailsForm.controls['current_position'].value,
      in_state: this.CreateCandidateDetailsForm.controls['in_state'].value,
      in_city: this.CreateCandidateDetailsForm.controls['in_city'].value,
      in_country_full_name:
        this.CreateCandidateDetailsForm.controls['country'].value,
      linkedin_url:
        this.CreateCandidateDetailsForm.controls['linkedin_url'].value,
    };

    this.CandidatejobsService.updateCandidateData(
      updatedCandidate.id,
      updatedCandidate
    ).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateCandidateDetailsForm.reset();
        this.notificationService.showSuccess(response.message);
        this.submitted = false;
        this.onload();
      },
      error: (error) => {
        this.CreateCandidateDetailsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.in_first_name) {
              this.CreateCandidateDetailsErrorFirstname =
                error.error.in_first_name[0];
            }
            if (error.error.in_last_name) {
              this.CreateCandidateDetailsErrorLastname =
                error.error.in_last_name[0];
            }
            if (error.error.in_birth_date) {
              this.CreateCandidateDetailsErrorInbirthdate =
                error.error.in_birth_date[0];
            }
            if (error.error.current_company) {
              this.CreateCandidateDetailsErrorCurrentcompany =
                error.error.current_company[0];
            }
            if (error.error.current_position) {
              this.CreateCandidateDetailsErrorCurrentposition =
                error.error.current_position[0];
            }
            if (error.error.in_state) {
              this.CreateContactsDetailsErrorInstate = error.error.in_state[0];
            }
            if (error.error.in_city) {
              this.CreateContactsDetailsErrorIncity = error.error.in_city[0];
            }
            if (error.error.in_country_full_name) {
              this.CreateContactsDetailsErrorIncountryfullname =
                error.error.in_country_full_name[0];
            }
            if (error.error.linkedin_url) {
              this.CreateCandidateDetailsErrorLinkedinurl =
                error.error.linkedin_url[0];
            }
            break;
          default:
        }
      },
    });
  }

  OpenContactDetails() {
    this.CreateCandidateDetailsForm.controls['id'].setValue(
      this.candidateData.id
    );
    this.CreateCandidateDetailsForm.controls['in_personal_phone'].setValue(
      this.candidateData.in_personal_phone
    );
    this.CreateCandidateDetailsForm.controls['in_personal_email'].setValue(
      this.candidateData.in_personal_email
    );
    this.CreateCandidateDetailsForm.controls['in_personal_email'].disable();
    this.CreateCandidateDetailsForm.controls['in_work_phone'].setValue(
      this.candidateData.in_work_phone
    );
    this.CreateCandidateDetailsForm.controls['in_work_email'].setValue(
      this.candidateData.in_work_email
    );
    this.CreateCandidateDetailsForm.controls[
      'is_in_personal_phone_preffered'
    ].setValue(this.candidateData.is_in_personal_phone_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_personal_email_preffered'
    ].setValue(this.candidateData.is_in_personal_email_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_work_phone_preffered'
    ].setValue(this.candidateData.is_in_work_phone_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_work_email_preffered'
    ].setValue(this.candidateData.is_in_work_email_preffered);
  }

  //  Update Contacts Details
  UpdateContactsDetails() {
    this.submitted = true;

    let updatedContactDetails = {
      id: this.CreateCandidateDetailsForm.controls['id'].value,
      in_personal_phone:
        this.CreateCandidateDetailsForm.controls['in_personal_phone'].value,
      in_personal_email:
        this.CreateCandidateDetailsForm.controls['in_personal_email'].value,
      in_work_phone:
        this.CreateCandidateDetailsForm.controls['in_work_phone'].value,
      in_work_email:
        this.CreateCandidateDetailsForm.controls['in_work_email'].value,
      is_in_personal_phone_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_personal_phone_preffered'
      ].value
        ? 1
        : 0,
      is_in_personal_email_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_personal_email_preffered'
      ].value
        ? 1
        : 0,
      is_in_work_phone_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_work_phone_preffered'
      ].value
        ? 1
        : 0,
      is_in_work_email_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_work_email_preffered'
      ].value
        ? 1
        : 0,
    };

    this.CandidatejobsService.updateCandidateData(
      updatedContactDetails.id,
      updatedContactDetails
    ).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateCandidateDetailsForm.reset();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.onload();
      },
      error: (error) => {
        this.CreateContactsDetailsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.in_personal_phone) {
              this.CreateContactsDetailsErrorInpersonalphone =
                error.error.in_personal_phone[0];
            }
            if (error.error.in_personal_email) {
              this.CreateContactsDetailsErrorInpersonalemail =
                error.error.in_personal_email[0];
            }
            if (error.error.in_work_phone) {
              this.CreateContactsDetailsErrorInworkphone =
                error.error.in_work_phone[0];
            }
            if (error.error.in_work_email) {
              this.CreateContactsDetailsErrorInworkemail =
                error.error.in_work_email[0];
            }
            if (error.error.is_in_personal_phone_preffered) {
              this.CreateContactsDetailsErrorisInpersonalphonepreffered =
                error.error.is_in_personal_phone_preffered[0];
            }
            if (error.error.is_in_personal_email_preffered) {
              this.CreateContactsDetailsErrorisInpersonalemailpreffered =
                error.error.is_in_personal_email_preffered[0];
            }
            if (error.error.is_in_work_phone_preffered) {
              this.CreateContactsDetailsErrorisInworkphonepreffered =
                error.error.is_in_work_phone_preffered[0];
            }
            if (error.error.is_in_work_email_preffered) {
              this.CreateContactsDetailsErrorisInworkemailpreffered =
                error.error.is_in_work_email_preffered[0];
            }

            break;
          default:
          //Nothing
        }
      },
    });
  }

  AddEducationDetails() {
    this.submitted = true;
    if (this.AddEducationForm.invalid) {
      return;
    }

    let AddEducationDetails = {
      degree_name: this.AddEducationForm.controls['degree_name'].value,
      school: this.AddEducationForm.controls['school'].value,
      start_date: this.AddEducationForm.controls['start_date'].value,
      end_date: this.AddEducationForm.controls['end_date'].value,
    };

    this.CandidatejobsService.createEducation(
      this.candidate_id,
      AddEducationDetails
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.modalService.dismissAll();
        this.projectapicall = false;
        this.AddEducationForm.reset();
        this.submitted = false;
        this.onload();
      },
      error: (error) => {
        this.CreateEducationDetailsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.degree_name) {
              this.CreateEducationDetailsErrorDegreename =
                error.error.degree_name[0];
            }
            if (error.error.school) {
              this.CreateEducationDetailsErrorSchool = error.error.school[0];
            }
            if (error.error.start_date) {
              this.CreateEducationDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.CreateEducationDetailsErrorEnddate = error.error.end_date[0];
            }
            break;
          default:
        }
      },
    });
  }

  EditEducationDetails(EditId: any) {
    let EducationDetail = this.educationData.find((c: any) => c.id === EditId);
    this.AddEducationForm.controls['education_id'].setValue(EducationDetail.id);
    this.AddEducationForm.controls['degree_name'].setValue(
      EducationDetail.degree_name
    );
    this.AddEducationForm.controls['school'].setValue(EducationDetail.school);
    this.AddEducationForm.controls['start_date'].setValue(
      EducationDetail.start_date
    );
    this.AddEducationForm.controls['end_date'].setValue(
      EducationDetail.end_date
    );
  }

  // Updatw Education
  UpdateEducationDetails() {
    this.submitted = true;
    if (this.AddEducationForm.invalid) {
      return;
    }
    let UpdateEduDetails = {
      edu_id: this.AddEducationForm.controls['education_id'].value,
      degree_name: this.AddEducationForm.controls['degree_name'].value,
      school: this.AddEducationForm.controls['school'].value,
      start_date: this.AddEducationForm.controls['start_date'].value,
      end_date: this.AddEducationForm.controls['end_date'].value,
    };

    this.CandidatejobsService.updateEducation(
      this.candidate_id,
      UpdateEduDetails.edu_id,
      UpdateEduDetails
    ).subscribe({
      next: (response: any) => {
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.AddEducationForm.reset();
        this.onload();
      },
      error: (error) => {
        this.UpdateEducationDetailsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.degree_name) {
              this.UpdateEducationDetailsErrorDegreename =
                error.error.degree_name[0];
            }
            if (error.error.school) {
              this.UpdateEducationDetailsErrorSchool = error.error.school[0];
            }
            if (error.error.start_date) {
              this.UpdateEducationDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.UpdateEducationDetailsErrorEnddate = error.error.end_date[0];
            }
            break;
          default:
        }
      },
    });
  }

  // Delete Education
  deleteEducationDetail() {
    this.CandidatejobsService.deleteEducation(
      this.candidate_id,
      this.deleteEducationId
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        // window.location.reload();
        this.onload();
        this.modalService.dismissAll();
      },
      error: (error) => {},
    });
  }

  AddCertificateDetails() {
    this.submitted = true;
    if (this.AddCertificateForm.invalid) {
      return;
    }

    let CertificateDetails = {
      id: this.candidate_id,
      name: this.AddCertificateForm.controls['name'].value,
      authority: this.AddCertificateForm.controls['authority'].value,
      start_date: this.AddCertificateForm.controls['start_date'].value,
      end_date: this.AddCertificateForm.controls['end_date'].value,
    };

    this.CandidatejobsService.createCertificate(
      CertificateDetails.id,
      CertificateDetails
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.modalService.dismissAll();
        this.projectapicall = false;
        this.submitted = false;
        this.onload();
        this.AddCertificateForm.reset();
      },
      error: (error) => {
        this.CreateCertificateDetailsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.CreateCertificateDetailsErrorName = error.error.name[0];
            }
            if (error.error.authority) {
              this.CreateCertificateDetailsErrorAuthority =
                error.error.authority[0];
            }
            if (error.error.start_date) {
              this.CreateCertificateDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.CreateCertificateDetailsErrorEnddate =
                error.error.end_date[0];
            }
            break;
          default:
        }
      },
    });
  }

  // Delete Certification
  deleteCertificateDetail() {
    this.CandidatejobsService.deleteCertificate(
      this.candidate_id,
      this.deleteCertificateId
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.onload();
        this.modalService.dismissAll();
      },
      error: (error: any) => {},
    });
  }

  EditCertificate(EditId: any) {
    this.submitted = true;
    let certificateDetails = this.certificateList.find(
      (c: any) => c.id === EditId
    );
    this.AddCertificateForm.controls['certificateId'].setValue(
      certificateDetails.id
    );
    this.AddCertificateForm.controls['name'].setValue(certificateDetails.name);
    this.AddCertificateForm.controls['authority'].setValue(
      certificateDetails.authority
    );
    this.AddCertificateForm.controls['start_date'].setValue(
      certificateDetails.start_date
    );
    this.AddCertificateForm.controls['end_date'].setValue(
      certificateDetails.end_date
    );
  }

  updateCertificateDetails() {
    this.submitted = true;
    if (this.AddCertificateForm.invalid) {
      return;
    }

    let CertificateDetails = {
      id: this.candidate_id,
      certificateId: this.AddCertificateForm.controls['certificateId'].value,
      name: this.AddCertificateForm.controls['name'].value,
      authority: this.AddCertificateForm.controls['authority'].value,
      start_date: this.AddCertificateForm.controls['start_date'].value,
      end_date: this.AddCertificateForm.controls['end_date'].value,
    };

    this.CandidatejobsService.updateCertificate(
      CertificateDetails.id,
      CertificateDetails.certificateId,
      CertificateDetails
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.AddCertificateForm.reset();
        this.onload();
        this.modalService.dismissAll();
      },
      error: (error) => {
        this.projectapicall = false;
        this.UpdateCertificateDetailsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.UpdateCertificateDetailsErrorName = error.error.name[0];
            }
            if (error.error.authority) {
              this.UpdateCertificateDetailsErrorAuthority =
                error.error.authority[0];
            }
            if (error.error.start_date) {
              this.UpdateCertificateDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.UpdateCertificateDetailsErrorEnddate =
                error.error.end_date[0];
            }
            break;
          default:
        }
      },
    });
  }

  // Upload Document
  UploadDocuments() {
    this.submitted = true;

    // console.log(this.documentDetails['description'].errors);
    if (!this.files) {
      return;
    }

    if (this.AddDocumentForm.invalid) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);
    formData.append(
      'description',
      this.AddDocumentForm.controls['description'].value
    );
    formData.append(
      'is_resume','1'
      // this.AddDocumentForm.controls['is_resume'].value
    );
    this.CandidatejobsService.createDocument(
      this.candidate_id,
      formData
    ).subscribe({
      next: (response: any) => {
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.submitted = false;
        this.onload();
        this.AddDocumentForm.reset();
      },
      error: (error) => {
        // console.log(error.error.document?.[0], "error");
        this.projectapicall = false;
        this.CreatDocumentsError = error.error.message;
        this.doctyoeerror = error.error.document[0];
        switch (error.status) {
          case 422:
            if (error.error.document) {
              this.CreatDocumentsErrorDocument = error.error.document[0];
            }
            if (error.error.description) {
              this.CreatDocumentsErrorDescription = error.error.description[0];
            }
            break;
          default:
        }
        this.cdr.detectChanges();
      },
    });
  }

  // Delete Document
  deleteDocuments() {
    this.CandidatejobsService.deleteDocument(
      this.candidate_id,
      this.deleteDocumentId
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.onload();
        this.modalService.dismissAll();
      },
      error: (err: any) => {},
    });
  }

  EditDocument(EditId: any) {
    let document = this.documentData.filter((ele: any) => ele.id == EditId);
    if(document[0].description === "string"){
      document[0].description = JSON.parse(document[0].description);
    }
    this.AddDocumentForm.controls['description'].setValue(''
      // document[0].description
    );
    this.AddDocumentForm.controls['documentId'].setValue(document[0].id);
  }

  // update document
  UpdateDocuments() {
    this.submitted = true;

    if (!this.files) {
      return;
    }

    // console.log(this.documentDetails['description'].errors);

    if (this.AddDocumentForm.invalid) {
      return;
    }

    let documentId = this.AddDocumentForm.controls['documentId'].value;
    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);
    formData.append(
      'description',
      this.AddDocumentForm.controls['description'].value
    );
    formData.append(
      'is_resume','1'
      // this.AddDocumentForm.controls['is_resume'].value
    );

    this.CandidatejobsService.updateDocument(
      this.candidate_id,
      documentId,
      formData
    ).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.modalService.dismissAll();
        this.onload();
      },
      error: (error) => {
        this.projectapicall = false;
        this.UpdateDocumentsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.document) {
              this.UpdateDocumentsErrorDocument = error.error.document[0];
            }
            if (error.error.description) {
              this.UpdateDocumentsErrorDescription = error.error.description[0];
            }
            break;
          default:
        }
      },
    });
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }

  restrictAlphabet(evt: any) {
    const keys = evt.keyCode;
    if (keys < 48 || keys > 58) {
      return false;
    } else {
      return true;
    }
  }

  getStates() {
    this.CandidatejobsService.getstate().subscribe({
      next: (response: any) => {
        this.stateData = response.states;
      },
      error: (error: any) => {},
    });
  }

  resetBackendAddEducationDetailserror() {
    this.CreateEducationDetailsErrorDegreename = '';
    this.CreateEducationDetailsErrorSchool = '';
    this.CreateEducationDetailsErrorStartdate = '';
    this.CreateEducationDetailsErrorEnddate = '';
    this.CreateEducationDetailsError = '';
    this.AddEducationForm.reset();
  }

  ResetAddCertifcationerror() {
    this.CreateCertificateDetailsErrorName = '';
    this.CreateCertificateDetailsErrorAuthority = '';
    this.CreateCertificateDetailsErrorStartdate = '';
    this.CreateCertificateDetailsErrorEnddate = '';
    this.CreateCertificateDetailsError = '';
    this.AddCertificateForm.reset();
  }

  resetBackendUploadDocumenterror() {
    this.CreatDocumentsErrorDocument = '';
    this.CreatDocumentsErrorDescription = '';
    this.CreatDocumentsError = '';
  }

  // handleOpen(tenant_id: any) {
  //   const tenanturl = `${tenant_id}/public`;
  //   this.router.navigate([tenanturl]);
  // }

  handleOpen(tenant_id: any) {
    const tenantUrl = `${tenant_id}/public`;
    window.open(tenantUrl, '_blank');
  }

  Updatesubmit() {
    this.submitted = true;
    this.updateURLsuccessMsg = '';

    if (this.UpdateUrlForm.invalid) {
      return;
    }

    let data = {
      candidate_id: this.candidate_id,
      url: this.UpdateUrlForm.controls['linkedinurl'].value,
    };

    this.CandidatejobsService.updateURL(this.candidate_id, data).subscribe({
      next: (response: any) => {
        this.onload();
        this.modalService.dismissAll();
        this.UpdateUrlForm.reset();
        this.notificationService.showSuccess(response.message);
      },
      error: (error) => {
        // this.notificationService.showError(error.message);
        this.addLinkedinURLError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.url) {
              this.linkedinurlError = error.error.url[0];
            }
            break;
          default:
        }
      },
    });
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) return '---'; // If the string is empty or undefined, return '---'
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  resetapplyerror() {
    this.project_idError = '';
    this.urlError = '';
    this.applyJobError = '';
    this.tenant_idError = '';
    this.applysuccessMsg = '';
    this.uploadresumesuccess = '';
  }

  uploadresume() {}

  onapplysubmit() {}
}
